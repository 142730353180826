import { useFormik } from 'formik';
import { flatMap, sortBy } from 'lodash';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useRegions } from '../../../hooks';
import { DropdownSelect } from '../../../shared/Components';
import './ExecutionContainer.scss';

const NewExecutionFilters = ({ filters, setFilters }) => {
  const history = useHistory();
  const { clients, serviceProviders } = useSelector(({ account, serviceProvider }) => ({
    clients: account.clients,
    serviceProviders: serviceProvider.listLight,
  }));
  const statesList = useRegions();
  const { materialCategories, materialItems } = useSelector(({ browseNodes }) => ({
    materialCategories: sortBy(
      browseNodes?.data?.filter((category) => category?.id !== '3' && category?.id !== '5'),
      'title'
    ),
    materialItems: flatMap(
      sortBy(
        browseNodes?.data?.filter((category) => category?.id !== '3' && category?.id !== '5'),
        'title'
      )?.map((data) => data?.items)
    ),
  }));

  const categoryMaterialList = [
    {
      label: 'MATERIAL CATEGORIES',
      options: materialCategories,
    },
    {
      label: 'MATERIAL ITEMS',
      options: materialItems?.filter((data) => data?.name !== 'Any'),
    },
  ];
  const formatCategoriesMaterialsGroupLabel = (groupedLabelData) => (
    <div className="groupStyles">
      <span>{groupedLabelData?.label} </span>
      <span className="groupBadgeStyles"> ({groupedLabelData?.options?.length})</span>
    </div>
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...filters,
      // categoryMaterialList : []
    },
    onSubmit: (values) => {
      setFilters(values);
    },
  });

  return (
    <Form noValidate className="w-100">
      <Row>
        {/* <HasAuthority authorities={[AUTHORITY2.SERVICE_PROVIDER]}> */}
        <Col>
          <Form.Group>
            <Form.Label className="execution_form_label">Brand</Form.Label>
            <DropdownSelect
              className="basic-single"
              classNamePrefix="select"
              isSearchable={true}
              isClearable={true}
              value={clients?.find((i) => i.id === filters?.clientId) || null}
              name="clientId"
              getOptionLabel={(option) => option['name']}
              getOptionValue={(option) => option['id']}
              options={clients}
              onChange={(value) => {
                formik.setFieldValue('clientId', value?.id);
                formik.handleSubmit();
              }}
              noOptionsMessage={() => 'Brands not found!'}
              styles={{
                menuPortal: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                }),
              }}
              menuPortalTarget={document.body}
            />
          </Form.Group>
        </Col>
        {/* </HasAuthority> */}

        <Col>
          <Form.Group>
            <Form.Label className="execution_form_label">States</Form.Label>
            <DropdownSelect
              className="basic-single"
              classNamePrefix="select"
              isSearchable={true}
              isClearable={true}
              // value={statesList?.find((i) => i.id === filters?.stateIds) || null}
              value={formik?.values?.['stateSelected'] || null}
              name="stateIds"
              isMulti={true}
              getOptionLabel={(option) => option['name']}
              getOptionValue={(option) => option['id']}
              options={statesList}
              onChange={(value) => {
                const data = value ? value?.map((data) => data?.id) : undefined;
                formik.setValues({
                  ...formik?.values,
                  stateSelected: value,
                  stateIds: data,
                });
                formik.handleSubmit();
              }}
              noOptionsMessage={() => 'States not found!'}
              styles={{
                menuPortal: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                }),
              }}
              menuPortalTarget={document.body}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label className="execution_form_label">Category / Material</Form.Label>
            <DropdownSelect
              value={formik?.values?.categoryMaterialList || ''}
              onChange={(value) => {
                if (value && value?.length > 0) {
                  const browseNodeIdsList = value?.filter((data) => data?.title);
                  const itemIdsList = value
                    ?.filter((item) => !item?.title && item?.id)
                    ?.map((data) => ({ id: data?.id, title: data?.name }));
                  const selectedItemIds = itemIdsList
                    ? itemIdsList?.map((data) => data?.id)
                    : undefined;
                  const selectedBrowseNodeIds = browseNodeIdsList
                    ? browseNodeIdsList?.map((data) => data?.id)
                    : undefined;
                  const currentCategoryMaterialList = formik?.values?.categoryMaterialList || [];
                  const updatedCategoryMaterialList = [
                    ...value?.filter(
                      (item) =>
                        !currentCategoryMaterialList?.some(
                          (existingItem) => existingItem?.id === item?.id
                        )
                    ), // Add newly selected items
                    ...currentCategoryMaterialList?.filter((existingItem) =>
                      value?.some((item) => item?.id === existingItem?.id)
                    ), // Keep already selected items
                  ];
                  formik.setValues({
                    ...formik?.values,
                    browseNodeIds: selectedBrowseNodeIds,
                    itemIds: selectedItemIds,
                    categoryMaterialList: updatedCategoryMaterialList,
                  });
                  formik.handleSubmit();
                } else {
                  formik.setValues({
                    ...formik?.values,
                    browseNodeIds: [],
                    itemIds: [],
                    categoryMaterialList: [],
                  });
                  formik.handleSubmit();
                }
              }}
              options={[categoryMaterialList[0], categoryMaterialList[1]]}
              isMulti={true}
              getOptionLabel={(option) => `${option?.title ? option?.title : option?.name}`}
              formatGroupLabel={formatCategoriesMaterialsGroupLabel}
              getOptionValue={(option) => `${option?.id}`}
              noOptionsMessage={() => 'Category, Material not found!'}
            />
          </Form.Group>
        </Col>
      </Row>
    </Form>
  );
};

export default NewExecutionFilters;

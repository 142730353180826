import React, { useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import MessageDotsCircle from '../../assets/img/MessageDotsCircle';
import back from '../../assets/img/back.png';
import HasAuthority from '../../shared/Components/HasAuthority/HasAuthorityNew';
import IdealVendorPricingModal from '../../views/MarginApproval/IdealVendorPricingModal';
import { MarginApproval } from '../../views/MarginApproval/marginApproval.service';
import './Planning.scss';
import PlanningCloseWorkOrderCreationModal from './PlanningModule/PlanningCloseWorkOrderModal';

export default function PlanningHeader(props) {
  const {
    isBackNavigation,
    isDraftWOView,
    handleDraftWoUpdate,
    checkIsFormValueEmpty,
    handleDeleteDraftWo,
    isCloseButton,
    isValueChanged,
    isLoading,
    isCloseWOButton,
    showCloseWorkOrderModal,
    handleCloseWorkOrderModal,
    handleCloseWorkOrder,
    closeWorkOrderLoading,
    workOrderId,
    workOrderComments,
    setShowCommentsModal,
  } = props;
  const history = useHistory();
  const [showVendorModal, setShowVendorModal] = useState(false);
  const [isBrandPricesLoading, setIsBrandPricesLoading] = useState(false);
  const [brandPricingList, setBrandPricingList] = useState();

  const fetchBrandPrices = async () => {
    setIsBrandPricesLoading(true);
    try {
      const response = await MarginApproval.getBrandPricing();
      if (response?.status === 200) {
        setBrandPricingList(response?.data?.[0]);
      }
    } catch (error) {
      toast.error('Something went wrong!');
    }
    setIsBrandPricesLoading(false);
  };
  const onClose = () => {
    setShowVendorModal(false);
  };

  return (
    <Card style={{ marginBottom: '15px' }}>
      <Card.Header className="bg-white border-5  py-0" style={{ borderBottom: '0px' }}>
        <Row className="pt-4 pb-3 align-self-center">
          <Col className="d-flex align-items-center" sm={9} md={9} lg={9}>
            {isBackNavigation && (
              <img className="planningHeaderImage" onClick={history.goBack} src={back} />
            )}
            {props.children}
          </Col>
          <Col className="align-items-center d-flex  justify-content-end" sm={3} md={3} lg={3}>
            {workOrderComments && workOrderComments !== '' && (
              <Button
                onClick={() => setShowCommentsModal(true)}
                className="save-btn text-dark"
                variant="outline-secondary"
                style={{ marginRight: '11px' }}>
                <MessageDotsCircle /> <span color="#004eeb">View Comments</span>
              </Button>
            )}
            <HasAuthority
              permissionsAllowed={[
                {
                  groupName: 'Margin_Approval',
                  moduleName: 'Vendor_Pricing',
                  name: 'can_view',
                },
              ]}>
              <Button
                variant="outline"
                className="mr-4 vendoridealpricing_style"
                onClick={() => setShowVendorModal(true)}>
                Vendor Pricing List
              </Button>
            </HasAuthority>
            {isCloseButton && <Button onClick={history.goBack}>Close</Button>}
            {isCloseWOButton && (
              <Button
                onClick={handleCloseWorkOrderModal}
                className="ml-2 brandwisePlannedEditViewBodyButtonStyle"
                variant="outline-secondary"
                disabled={closeWorkOrderLoading}>
                Close WO
              </Button>
            )}
            {isDraftWOView && (
              <div>
                <HasAuthority
                  permissionsAllowed={[
                    { groupName: 'Planning', moduleName: 'Planning', name: 'resend_consent' },
                  ]}>
                  <Button
                    className="mr-2"
                    onClick={() => handleDraftWoUpdate()}
                    disabled={!isValueChanged || isLoading || checkIsFormValueEmpty()}>
                    Resend for Consent
                  </Button>
                </HasAuthority>
                <HasAuthority
                  permissionsAllowed={[
                    { groupName: 'Planning', moduleName: 'Planning', name: 'delete_draft_wo' },
                  ]}>
                  <Button onClick={() => handleDeleteDraftWo()}>Delete Draft WO</Button>
                </HasAuthority>
              </div>
            )}
          </Col>
        </Row>
        {showCloseWorkOrderModal && (
          <PlanningCloseWorkOrderCreationModal
            showCloseWorkOrderModal={showCloseWorkOrderModal}
            handleCloseWorkOrderModal={handleCloseWorkOrderModal}
            handleCloseWorkOrder={handleCloseWorkOrder}
            closeWorkOrderLoading={closeWorkOrderLoading}
            workOrderId={workOrderId}
          />
        )}
      </Card.Header>

      <IdealVendorPricingModal
        showModal={showVendorModal}
        onClose={onClose}
        fetchBrandPrices={fetchBrandPrices}
        setShowVendorModal={setShowVendorModal}
        type="view"
      />
    </Card>
  );
}

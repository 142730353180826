import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import CloseIcon from '../../../assets/imageComponents/CloseIcon';
import UploadArrowIcon from '../../../assets/imageComponents/UploadArrowIcon';
import { uploadDocument } from '../../../shared/Utils/FileUpload';

function POModal({
  show,
  // handleClose,
  workOrderForm,
  handleDocumentChange,
  deleteDocument,
  isPOuploaded,
  isEdit,
  isProgress,
  initialDocuments,
  handleSaveAsDraft,
  submitForInreviewClicked,
  handlesaveEditedDetails,
  submitData,
  disableUploadPoAndPriceReview,
  setShowPOModal,
  isLoading,
}) {
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [showFields, setShowFields] = useState(false);
  const poNumber = workOrderForm.values?.poNumber?.trim() || '';
  const poDate = workOrderForm.values?.poDate || '';
  const purchaseOrderDocs = workOrderForm?.values?.poDocuments;

  const [localPoNumber, setLocalPoNumber] = useState(workOrderForm.values?.poNumber?.trim() || '');
  const [localPoDate, setLocalPoDate] = useState(workOrderForm.values?.poDate || '');

  useEffect(() => {
    setIsButtonDisabled(!(poNumber && poDate && (purchaseOrderDocs?.length > 0 || isPOuploaded)));
  }, [poNumber, poDate, purchaseOrderDocs]);

  const DOCUMENTS_UPLOAD = [
    {
      id: 1,
      label: 'Purchase Order',
      fileName: 'Purchase_order',
      type: 'WO_PURCHASE_ORDER',
      entity: 'WORK_ORDER',
      required: true,
      disabled:
        isEdit &&
        isProgress &&
        !!initialDocuments?.some((doc) => doc?.type === 'WO_PURCHASE_ORDER'),
    },
  ];

  const formattedPoDate = moment(workOrderForm.values.poDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

  const handleFileUpload = (files) => {
    if (!files.length) return;
    const file = files[0];

    const fileDetails = {
      active: true,
      entity: 'WORK_ORDER',
      fileName: file.name,
      fileSize: file.size,
      contentType: file.type,
      type: 'WO_PURCHASE_ORDER',
    };

    let docTobeUploaded = { status: '', fileName: '' };

    const signedURLCallback = (doc) => {
      docTobeUploaded = { ...doc };
    };

    const successCallback = () => {
      docTobeUploaded.status = 'DONE';
      handleDocumentChange([docTobeUploaded], true);
      toast.success('PO Document uploaded successfully!');
    };

    const failureCallback = () => {
      docTobeUploaded.status = 'FAIL';
      toast.error('Failed to upload PO document');
    };

    uploadDocument(fileDetails, file, signedURLCallback, successCallback, failureCallback);
  };
  const handlePOModalClose = () => {
    setLocalPoDate('');
    setLocalPoNumber('');
    setShowPOModal(false);
  };
  return (
    <Modal show={show} onHide={handlePOModalClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{showFields ? 'PO Details' : 'Work Order Confirmation'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!showFields ? (
          <>
            <p style={{ fontSize: '20px' }}>
              The entered prices match the minimum pricing requirements. You can proceed with this
              Work Order.
            </p>
          </>
        ) : (
          <Form>
            <Col xs={12} className="mb-3">
              <Form.Group>
                <Form.Label>
                  PO Number <span className="mandatory-astrik-color">*</span>
                </Form.Label>
                <Form.Control
                  name="poNumber"
                  id="poNumber"
                  value={localPoNumber}
                  isInvalid={!!workOrderForm.errors.poNumber && workOrderForm.touched.poNumber}
                  onChange={(e) => {
                    const value = e.target.value;
                    setLocalPoNumber(value);
                    workOrderForm.setFieldValue('poNumber', value);
                  }}
                />
              </Form.Group>
            </Col>
            <Col xs={12} className="mb-3">
              <Form.Group>
                <Form.Label>
                  PO Date <span className="mandatory-astrik-color">*</span>
                </Form.Label>
                <Form.Control
                  type="date"
                  name="poDate"
                  id="poDate"
                  isInvalid={!!workOrderForm.errors.poDate && workOrderForm.touched.poDate}
                  value={
                    localPoDate ? moment(workOrderForm.values.poDate).format('YYYY-MM-DD') : ''
                  }
                  onChange={(e) => {
                    setLocalPoDate(
                      moment(e.target.value, 'YYYY-MM-DD').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
                    );
                    workOrderForm.setFieldValue(
                      'poDate',
                      moment(e.target.value, 'YYYY-MM-DD').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
                    );
                  }}
                />

                <Form.Control.Feedback type="invalid">
                  {workOrderForm.errors.poDate}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            {!isPOuploaded && (
              <>
                <Col xs={12} className="mb-3">
                  <Form.Group>
                    <Form.Label>
                      Upload Purchase Order (PDF, PNG, JPG){' '}
                      <span className="mandatory-astrik-color">*</span>
                    </Form.Label>
                    <div className="d-flex justify-content-center align-items-center">
                      <label
                        htmlFor="po-upload"
                        style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center' }}>
                        <UploadArrowIcon width={50} height={50} />
                      </label>
                      <input
                        type="file"
                        accept="application/pdf,image/png,image/jpeg"
                        id="po-upload"
                        style={{ display: 'none' }}
                        onChange={(e) => handleFileUpload(e.target.files)}
                      />
                    </div>
                  </Form.Group>
                </Col>

                <Col xs={12}>
                  <h6>Uploaded Documents</h6>
                  {purchaseOrderDocs?.length > 0 ? (
                    purchaseOrderDocs?.map((doc, index) => (
                      <div
                        key={index}
                        className="d-flex justify-content-between align-items-center">
                        <a href={doc.viewUrl} target="_blank" rel="noopener noreferrer">
                          {`Purchase_Order_${index + 1}`}
                        </a>
                        <Button
                          onClick={() => {
                            if (!DOCUMENTS_UPLOAD[0]?.disabled) {
                              deleteDocument(doc, true);
                            }
                          }}
                          variant="outline">
                          <CloseIcon strokeColor="#D92D20" />
                        </Button>
                      </div>
                    ))
                  ) : (
                    <p>No purchase order uploaded</p>
                  )}
                </Col>
              </>
            )}
          </Form>
        )}
      </Modal.Body>
      <Modal.Footer>
        {!submitForInreviewClicked &&
          workOrderForm.values.status !== 'PRICING_APPROVED' &&
          workOrderForm.values.status !== 'REJECTED' && (
            <Button variant="secondary" onClick={handleSaveAsDraft}>
              Save as Draft
            </Button>
          )}
        {!showFields ? (
          <Button
            variant="primary"
            onClick={() => {
              setShowFields(true);
            }}
            disabled={
              disableUploadPoAndPriceReview ||
              !workOrderForm.values.paymentTerm ||
              workOrderForm.errors?.isInvoicing
            }>
            Update PO Details
          </Button>
        ) : (
          <Button
            variant="primary"
            type="submit"
            onClick={isProgress && isEdit ? handlesaveEditedDetails : submitData}
            disabled={isButtonDisabled || isLoading}>
            Submit for Review
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default POModal;

import { capitalize, replace } from 'lodash';
class AppAnalytics {
  static business = {};
  static user = {};
  /**
   * Initialize analytitcs
   */
  static init = () => {
    console.log('Init Analytics');
  };

  static setUserData = (user) => {
    this.business = user?.profile;
    this.user = user;
    this.updateUserProperties();
  };

  static updateUserProperties = () => {
    try {
      // eslint-disable-next-line no-undef
      heap.identify(this.user?.email);
      // eslint-disable-next-line no-undef
      heap.addEventProperties({ 'Logged In': 'true' });
      // eslint-disable-next-line no-undef
      AppAnalytics.sendExtraData({
        Id: this.user.id,
        email: this.user?.email,
        UserId: this.user.id,
        UserName: this.user.firstName,
        Actor:
          this.business?.productSubscriptions &&
          capitalize(replace(this.business?.productSubscriptions[0]?.actor, '_', ' ')),
      });
    } catch (e) {
      console.warn('AppAnalytics', e);
    }
  };

  static sendExtraData = (data) => {
    // eslint-disable-next-line no-undef
    heap.addUserProperties(data);
    // eslint-disable-next-line no-undef
    analytics.identify(this.user?.email, data);
  };

  static trackEvent = (eventName, data) => {
    if (window.heap) {
      window.heap.track(eventName, data);
    } else {
      console.error('Heap is not initialized');
    }
  };

  static clearUserProperties = () => {
    // eslint-disable-next-line no-undef
    heap.clearEventProperties();
    // eslint-disable-next-line no-undef
    heap.resetIdentity();
    // eslint-disable-next-line no-undef
    analytics.reset();
  };
  /**
   * Log event
   * @param eventName
   * @param params
   */
  static logEvent = (eventName, params) => {
    console.log(eventName, params);
  };
}

export default AppAnalytics;

import React, { useEffect } from 'react';
import { Button, ButtonToolbar, Card, Col, Row } from 'react-bootstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ExecutionFilters, ExecutionTargetList } from '../../../features/Execution';
import ExecutionTableLoader from '../../../features/Execution/ExecutionTargetList/ExecutionTableLoader';
import { useAccount } from '../../../hooks';
import { SummaryViewToggleButton } from '../../../shared/Components';
import HasAuthority, {
  useAuthority,
} from '../../../shared/Components/HasAuthority/HasAuthorityNew';
import { AUTHORITY2 } from '../../../shared/Constants/Constants';
import { ChangeView } from '../../../state/Execution/executionSlice';
import { fetchTargetExecution } from '../../../state/Execution/executionThunks';
import { getExecutionSummary } from '../../../state/Execution/selectors';
import './ExecutionContainer.scss';
import NewExecutionScreen from './NewExecutionScreen';

const ExecutionContainer = (props) => {
  const history = useHistory();
  const account = useAccount();
  const dispatch = useDispatch();
  const { hasAuth } = useAuthority();
  const isPRO = hasAuth([AUTHORITY2.PRO]);
  const { summary, filters, view, isFetching, currentFinancialYear } = useSelector(
    ({ execution, browseNodes, region, account }) => {
      return {
        ...execution,
        currentFinancialYear: account.selectedFinancialYear,
        summary: getExecutionSummary({
          ...execution,
          browseNodes: browseNodes.data,
          region: region.data,
          currentCustomerId: account.user?.profile?.id,
        }),
      };
    }
  );

  const handleViewChange = (view) => {
    dispatch(ChangeView(view));
  };

  useEffect(() => {
    !isPRO && loadSummaryData();
  }, [currentFinancialYear]);

  function loadSummaryData() {
    dispatch(fetchTargetExecution(currentFinancialYear));
  }

  const isNonPlastic = account?.user?.businessVerticals?.some((vertical) =>
    ['TYRES', 'E_WASTE', 'BATTERIES'].includes(vertical)
  );

  return (
    <>
      {isPRO ? (
        <NewExecutionScreen filters={filters} />
      ) : (
        <div className="animated fadeIn ExecutionContainer">
          <Card className="main-card">
            <Card.Header className="bg-white border-0  py-0">
              <Row className="padding-aligned align-self-center">
                <Col className="align-self-center" md={4}>
                  <h4>Executions </h4>
                </Col>
                <Col className="text-right">
                  <ButtonToolbar className="float-right">
                    <HasAuthority authorities={['ROLE_SERVICE_PROVIDER']}>
                      <Button
                        onClick={() => {
                          history.push('executions/deliveries/create');
                        }}>
                        New delivery
                      </Button>
                    </HasAuthority>
                    {isNonPlastic && (
                      <Button
                        onClick={() => {
                          history.push('work-orders/create');
                        }}>
                        New Work Order
                      </Button>
                    )}
                  </ButtonToolbar>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body className="border-top p-0">
              <PerfectScrollbar options={{ suppressScrollX: true }} style={{ height: '72vh' }}>
                <Row noGutters>
                  <Col className="bg-secondary p-4">
                    <ExecutionFilters data={summary} />
                  </Col>
                </Row>
                <Row noGutters>
                  <Col md={12} className="px-4 pt-4 text-right">
                    <SummaryViewToggleButton
                      handleViewChange={handleViewChange}
                      activeView={view}
                    />
                  </Col>
                  <Col className="p-4">
                    {isFetching ? (
                      <ExecutionTableLoader />
                    ) : (
                      <ExecutionTargetList
                        view={view}
                        loading={isFetching}
                        filters={filters}
                        data={summary}
                      />
                    )}
                  </Col>
                </Row>
              </PerfectScrollbar>
            </Card.Body>
          </Card>
        </div>
      )}
    </>
  );
};

export default ExecutionContainer;

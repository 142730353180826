import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { areArrayOfObjectsSame } from '../../Utils/Utils';
import './MultipleCheckbox.scss';

const MultipleCheckbox = ({ options, selectedValues = [], onChange }) => {
  const [show, setShow] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState(
    Array.isArray(selectedValues) ? selectedValues : []
  );
  const [isApplyDisabled, setIsApplyDisabled] = useState(true);

  useEffect(() => {
    setSelectedOptions(selectedValues);
  }, [selectedValues]);

  useEffect(() => {
    const isUnchanged =
      (selectedOptions?.length === selectedValues?.length &&
        selectedValues.every((val) => selectedOptions.includes(val))) ||
      !selectedOptions?.length;
    setIsApplyDisabled(isUnchanged);
  }, [selectedOptions, selectedValues]);

  const handleChange = (event) => {
    const value = event.target.name;

    if (value === 'ALL') {
      setSelectedOptions((prev) => (prev.includes('ALL') ? [] : options.map((opt) => opt.value)));
    } else {
      setSelectedOptions((prev) => {
        const updatedSelection = prev.includes(value)
          ? prev.filter((item) => item !== value)
          : [...prev, value];
        const allOptions = options.filter((item) => item?.value !== 'ALL')?.map((opt) => opt.value);
        if (areArrayOfObjectsSame(updatedSelection?.sort(), allOptions?.sort())) {
          return ['ALL', ...allOptions];
        } else {
          return updatedSelection.filter((item) => item !== 'ALL');
        }
      });
    }
  };

  const handleApply = () => {
    if (!isApplyDisabled) {
      onChange(selectedOptions?.filter((item) => item !== 'ALL'));
      setShow(false);
    }
  };

  const handleClear = () => {
    setSelectedOptions([]);
  };

  const handleClose = () => {
    setSelectedOptions(selectedValues);
    setShow(false);
  };

  const displayText =
    Array.isArray(selectedOptions) && selectedOptions.length > 0
      ? selectedOptions
          .map((val) => options.find((opt) => opt.value === val)?.label || val)
          .join(', ')
      : 'Select';

  const truncatedText = displayText.length > 15 ? displayText.slice(0, 15) + '...' : displayText;

  return (
    <Dropdown show={show} onToggle={(isOpen) => (isOpen ? setShow(true) : handleClose())}>
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip>{displayText}</Tooltip>}
        delay={{ show: 500, hide: 200 }}>
        <Dropdown.Toggle variant="light" className="dropdown-toggle-btn">
          {truncatedText}
        </Dropdown.Toggle>
      </OverlayTrigger>

      <Dropdown.Menu
        className="custom-dropdown"
        style={{ zIndex: 9999, position: 'absolute', top: '100%', left: 0 }}>
        <Form className="dropdown-form">
          {options.map((option) => (
            <Form.Check
              key={option.value}
              type="checkbox"
              label={option.label}
              name={option.value}
              checked={selectedOptions?.includes(option.value)}
              onChange={handleChange}
              className="multiple_checkbox_style"
            />
          ))}
        </Form>

        <div className="dropdown-buttons">
          <Button
            variant="primary"
            size="sm"
            onClick={handleApply}
            className="apply-btn"
            disabled={isApplyDisabled}>
            Apply
          </Button>
          <Button variant="primary" size="sm" onClick={handleClear} className="clear-btn">
            Clear
          </Button>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default MultipleCheckbox;

import React from 'react';
import { Table } from 'react-bootstrap';
import { useSortBy, useTable } from 'react-table';
import { BRAND_WO_STATUS_WITHOUT_COLUMNS } from '../../../shared/Constants/Constants';

function DeletedLineItemsList({
  isEdit,
  basicDetails,
  browseNodes,
  regions,
  columns,
  deletedRows,
  isHUL,
  isPRO,
}) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns: columns,
      data: deletedRows || [],
      initialState: {
        hiddenColumns: [
          !isHUL && 'poLineItem',
          !isPRO && 'sac',
          BRAND_WO_STATUS_WITHOUT_COLUMNS.includes(basicDetails?.status) && 'plannedQty',
          BRAND_WO_STATUS_WITHOUT_COLUMNS.includes(basicDetails?.status) && 'fulfilledQty',
          !isEdit && 'plannedQty',
          !isEdit && 'fulfilledQty',
        ],
      },
    },
    useSortBy
  );

  return (
    <Table
      {...getTableProps()}
      responsive
      className="table-bordered border-bottom-0 work-order-items-edit">
      <thead className="thead-light table-heading">
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                <div className={'d-flex flex-row align-items-center'}>
                  {column.render('Header')}
                </div>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, index) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
              })}
            </tr>
          );
        })}
        <p style={{ marginTop: '5px' }}>Count: {deletedRows?.length}</p>
      </tbody>
    </Table>
  );
}

export default DeletedLineItemsList;

import { useFormik } from 'formik';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Col, Modal, Row, Table } from 'react-bootstrap';
import { useSortBy, useTable } from 'react-table';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import editIcon from '../../assets/img/edit-icon-blue-outline.svg';
import { DeliveryService } from '../../features/Delivery/delivery.service';
import { InputCell, InputDateCell } from '../../features/WorkOrder/TableCells/TableCells';
import { HasAuthority, Loader } from '../../shared/Components';
import { toCapitalize } from '../../shared/Utils/Helper';
import './MarginApproval.scss';
import { MarginApproval } from './marginApproval.service';

function IdealVendorPricingModal({
  showModal,
  onClose,
  fetchBrandPrices,
  setShowVendorModal,
  type,
}) {
  const [isEdit, setIsEdit] = useState(false);
  const [pricingList, setPricingList] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [ID, setID] = useState(null);

  const defaultCategoryItems = {
    onlyCreditEOL: 0,
    withDocEOL: 0,
    onlyCreditRecycling: 0,
    withDocRecycling: 0,
    validTill: '',
    updatedBy: '-',
    updatedAt: '',
  };

  const generateCatItems = (categories = []) => {
    return categories.map((category) => ({
      category,
      ...defaultCategoryItems,
    }));
  };

  const setCatItems = generateCatItems(['Cat 1', 'Cat 2', 'Cat 3', 'Cat 4']);

  const formik = useFormik({
    initialValues: {
      moduleType: 'vendor',
      attributeType: 'pricing',
      catItems: !isEmpty(pricingList)
        ? pricingList?.catItems?.map((item) => ({
            category: item?.category || '',
            onlyCreditEOL: item?.processingType?.onlyCredit?.EOL || 0,
            withDocEOL: item?.processingType?.withDoc?.EOL || 0,
            onlyCreditRecycling: item?.processingType?.onlyCredit?.Recycling || 0,
            withDocRecycling: item?.processingType?.withDoc?.Recycling || 0,
            validTill: item?.validTill || '',
            updatedBy: item?.updatedBy || '-',
            updatedAt: item?.updatedAt || '-',
          }))
        : setCatItems,
    },
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema: Yup.object().shape({
      catItems: Yup.array().of(
        Yup.object().shape({
          category: Yup.string().required('Category is required'),
          onlyCreditEOL: Yup.number().required('This field is required'),
          withDocEOL: Yup.number().required('This field is required'),
          onlyCreditRecycling: Yup.number().required('This field is required'),
          withDocRecycling: Yup.number().required('This field is required'),
          validTill: Yup.string().required('Valid Till date is required'),
        })
      ),
    }),
    onSubmit: (values, actions) => {
      const payload = {
        moduleType: values?.moduleType,
        attributeType: values?.attributeType,
        catItems: values?.catItems?.map((item) => ({
          category: item?.category,
          processingType: {
            onlyCredit: {
              EOL: item?.onlyCreditEOL,
              Recycling: item?.onlyCreditRecycling,
            },
            withDoc: {
              EOL: item?.withDocEOL,
              Recycling: item?.withDocRecycling,
            },
          },
          validTill: moment(item?.validTill).format('YYYY-MM-DD'),
        })),
        customerId: null,
      };
      if (isEmpty(values)) {
        createVendorPrices(payload);
      } else {
        updateVendorPrices(payload);
      }
    },
  });

  const updateDataToTable = (rowIndex, columnId, value) => {
    const updatedCatItems = [...formik.values.catItems];
    updatedCatItems[rowIndex] = {
      ...updatedCatItems[rowIndex],
      [columnId]: value,
    };
    formik.setFieldValue('catItems', updatedCatItems);
  };

  const columns = useMemo(
    () => [
      {
        id: 1,
        Header: '',
        columns: [
          {
            Header: '',
            accessor: 'category',
            Cell: (cellInfo) => (
              <span>{cellInfo?.value ? toCapitalize(cellInfo.value?.replace('-', ' ')) : '-'}</span>
            ),
          },
        ],
      },
      {
        Header: 'EOL',
        columns: [
          {
            Header: 'Only Credit',
            accessor: 'onlyCreditEOL',
            Cell: (cellInfo) => {
              const rowIndex = cellInfo?.cell?.row?.index;
              return (
                <span>
                  {isEdit ? (
                    <InputCell
                      value={cellInfo?.value}
                      cellInfo={cellInfo}
                      inputType={'number'}
                      step={'any'}
                      placeholder={'Enter'}
                      updateHandler={updateDataToTable}
                    />
                  ) : (
                    cellInfo.value
                  )}
                </span>
              );
            },
          },
          {
            Header: 'With Doc.',
            accessor: 'withDocEOL',
            Cell: (cellInfo) => {
              return (
                <span>
                  {isEdit ? (
                    <InputCell
                      value={cellInfo?.value}
                      cellInfo={cellInfo}
                      inputType={'number'}
                      step={'any'}
                      placeholder={'Enter'}
                      updateHandler={updateDataToTable}
                    />
                  ) : (
                    cellInfo.value
                  )}
                </span>
              );
            },
          },
        ],
      },
      {
        Header: 'Recycling',
        columns: [
          {
            Header: 'Only Credit',
            accessor: 'onlyCreditRecycling',
            Cell: (cellInfo) => {
              return (
                <span>
                  {isEdit ? (
                    <InputCell
                      value={cellInfo?.value}
                      cellInfo={cellInfo}
                      inputType={'number'}
                      step={'any'}
                      placeholder={'Enter'}
                      updateHandler={updateDataToTable}
                    />
                  ) : (
                    cellInfo.value
                  )}
                </span>
              );
            },
          },
          {
            Header: 'With Doc.',
            accessor: 'withDocRecycling',
            Cell: (cellInfo) => {
              return (
                <span>
                  {isEdit ? (
                    <InputCell
                      value={cellInfo?.value}
                      cellInfo={cellInfo}
                      inputType={'number'}
                      step={'any'}
                      placeholder={'Enter'}
                      updateHandler={updateDataToTable}
                    />
                  ) : (
                    cellInfo.value
                  )}
                </span>
              );
            },
          },
        ],
      },
      {
        Header: 'Valid Till',
        columns: [
          {
            Header: '',
            accessor: 'validTill',
            Cell: (cellInfo) => {
              const rowIndex = cellInfo?.cell?.row?.index;
              return (
                <span>
                  {isEdit ? (
                    <InputDateCell
                      cellInfo={cellInfo}
                      required={true}
                      controlName="estimatedDelivery"
                      inputType={'date'}
                      placeholder={'Estimate delivery date'}
                      updateHandler={updateDataToTable}
                    />
                  ) : cellInfo.value ? (
                    moment(cellInfo.value).format('DD-MM-YYYY')
                  ) : (
                    ''
                  )}
                </span>
              );
            },
          },
        ],
      },
      {
        Header: 'Updated By',
        columns: [
          {
            Header: '',
            accessor: 'updatedBy',
            Cell: (cellInfo) => {
              const updatedAt = cellInfo.row.original.updatedAt;
              const userName = users?.find((user) => user?.id === cellInfo?.value)?.name;
              return (
                <div>
                  {userName}
                  <div className="updated-date">
                    {updatedAt ? moment(updatedAt).format('DD-MM-YYYY') : ''}
                  </div>
                </div>
              );
            },
          },
        ],
      },
    ],
    [formik.values, isEdit, users, pricingList]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data: formik.values.catItems,
      autoResetSelectedRows: false,
      autoResetFilters: false,
      autoResetSortBy: false,
      initialState: {
        hiddenColumns: [],
      },
      manualPagination: true,
    },
    useSortBy
  );

  const fetchVendorPrices = async () => {
    setIsLoading(true);
    try {
      const response = await MarginApproval.getVendorPricing();
      if (response?.status === 200) {
        setPricingList(response?.data?.[0]);
        setID(response?.data?.[0]?.id);
      }
    } catch (error) {
      toast.error('Something went wrong!');
    }
    setIsLoading(false);
  };

  const updateVendorPrices = async (payload) => {
    setIsLoading(true);
    try {
      const response = await MarginApproval.updateVendorPricing({ id: ID, ...payload });
      if (response?.status === 200) {
        fetchVendorPrices();
        setIsEdit(false);
        fetchBrandPrices();
        toast.success('Updated vendor prices successfully!');
      }
    } catch (error) {
      toast.error('Something went wrong!');
    }
    setIsLoading(false);
  };

  const createVendorPrices = async (payload) => {
    setIsLoading(true);
    try {
      const response = await MarginApproval.createVendorPricing(payload);
      if (response?.status === 200) {
        fetchVendorPrices();
        setIsEdit(false);
        fetchBrandPrices();
        toast.success('Created vendor prices successfully1');
      }
    } catch (error) {
      toast.error('Something went wrong!');
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchVendorPrices();
  }, []);

  const discardChanges = () => {
    setIsEdit(false);
    formik.resetForm();
  };

  const fetchUserList = async () => {
    try {
      const response = await DeliveryService.getUsersDetails();
      if (response.status === 200) {
        setUsers(response?.data);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const closeModal = () => {
    setIsEdit(false);
    formik.resetForm();
    setShowVendorModal(false);
  };

  useEffect(() => {
    fetchUserList();
  }, []);

  return (
    <Modal show={showModal} closeButton onHide={closeModal} centered size="xl">
      <Modal.Header className="border-0 pt-5 pr-5 pl-5 pb-1" style={{ display: 'block' }}>
        <Row className="mb-4">
          <Col md={6}>
            <h5>Vendor Pricing List</h5>
          </Col>
          <Col md={6} className="text-right">
            {!isEdit && (
              <Button className="cancel-btn mr-2" variant="outline-secondary" onClick={onClose}>
                Cancel
              </Button>
            )}
            <HasAuthority
              permissionsAllowed={[
                {
                  groupName: 'Margin_Approval',
                  moduleName: 'Vendor_Pricing',
                  name: 'can_edit',
                },
              ]}>
              {type !== 'view' && (
                <>
                  {isEdit ? (
                    <>
                      <Button
                        className="cancel-btn mr-2"
                        variant="outline-secondary"
                        onClick={() => discardChanges()}>
                        Discard Changes
                      </Button>
                      <Button type="submit" onClick={formik.handleSubmit}>
                        Save
                      </Button>
                    </>
                  ) : (
                    <Button className="edit-btn" onClick={() => setIsEdit(true)}>
                      <img src={editIcon} width={15} height={15} alt="edit" className="mr-2" />
                      Edit
                    </Button>
                  )}
                </>
              )}
            </HasAuthority>
          </Col>
        </Row>
      </Modal.Header>
      <Modal.Body className="pr-5 pl-5 pb-5">
        <Row>
          <Col md={12}>
            {isLoading ? (
              <Loader />
            ) : (
              <Table {...getTableProps()} className="vendor-pricing-table bordered" responsive>
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()} className="tr">
                      {headerGroup.headers.map((column) => (
                        <>
                          <th {...column.getHeaderProps()} className="th">
                            <div>{column.render('Header')}</div>
                          </th>
                        </>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {rows.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => (
                          <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            )}
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default IdealVendorPricingModal;

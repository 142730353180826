import React, { useMemo } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import SearchIcon from '../../assets/img/search-icon-grey.svg';
import { DropdownSelect } from '../../shared/Components';
import CustomDateRangePicker from '../../shared/Components/CustomDateRangePicker';
import MultipleCheckbox from '../../shared/Components/MultipleCheckbox/MultipleCheckbox';
import TooltipOnHover from '../../shared/Components/TooltipOnHover';
import {
  BRAND_WO_CARD_KEYS,
  VERTICALS_MAP,
  WO_STATUSES,
  poOptions,
} from '../../shared/Constants/Constants';
import { parseQueryParams, toLocalDate } from '../../shared/Utils/Helper';
import './WorkOrder.scss';

function renderColumn(
  param,
  filterValue,
  setFilter,
  clients,
  account,
  vertical,
  uniqueClients,
  activeCard,
  urlParams,
  options
) {
  switch (param) {
    case 'id':
      return (
        <InputGroup>
          <Form.Control
            value={filterValue || ''}
            onChange={(e) => setFilter(e.target.value)}
            placeholder="Search"
            className="column-filter-input"
          />
          <InputGroup.Text className="column-filter-input-icon">
            <img src={SearchIcon} width="15px" height="15px" alt="search" />
          </InputGroup.Text>
        </InputGroup>
      );
    case 'fromCustomerId':
      return (
        <DropdownSelect
          options={clients}
          getOptionLabel={(option) => `${option?.name}`}
          getOptionValue={(option) => `${option?.id}`}
          noOptionsMessage={() => 'Options not found!'}
          onChange={(e) => {
            setFilter(e?.id);
          }}
          value={filterValue || ''}
          className="column-filter-dropdown"
          placeholder="Select"
          isClearable={true}
        />
      );
    case 'poNumber':
      return (
        <InputGroup>
          <Form.Control
            value={filterValue || ''}
            onChange={(e) => setFilter(e.target.value)}
            placeholder="Search"
            className="column-filter-input"
          />
          <InputGroup.Text className="column-filter-input-icon">
            <img src={SearchIcon} width="15px" height="15px" alt="search" />
          </InputGroup.Text>
        </InputGroup>
      );
    case 'createdOn':
      return filterValue?.[0] && filterValue?.[1] ? (
        <>
          <TooltipOnHover
            dataStyle={{ color: '#005dff', cursor: 'pointer' }}
            msg={`${toLocalDate(filterValue[0])} to ${toLocalDate(filterValue[1])}`}>
            <CustomDateRangePicker
              parentEl="#orderDate"
              id="orderDate"
              initialSettings={{
                autoUpdateInput: false,
                locale: {
                  cancelLabel: 'Clear',
                },
                maxDate: new Date(),
                alwaysShowCalendars: false,
              }}
              startDate={filterValue ? filterValue[0] : ''}
              endDate={filterValue ? filterValue[1] : ''}
              placeholder="Select"
              onApply={({ startDate, endDate }, $event) => {
                setFilter([startDate, endDate]);
              }}
              onCancel={(event, picker) => {
                picker.element.val('');
                setFilter(undefined);
              }}
              setFilter={setFilter}
              inputClass="custom-datepicker"
            />
          </TooltipOnHover>
        </>
      ) : (
        <>
          <CustomDateRangePicker
            parentEl="#orderDate"
            id="orderDate"
            initialSettings={{
              autoUpdateInput: false,
              locale: {
                cancelLabel: 'Clear',
              },
              maxDate: new Date(),
              alwaysShowCalendars: false,
            }}
            startDate={filterValue ? filterValue[0] : ''}
            endDate={filterValue ? filterValue[1] : ''}
            placeholder="Select"
            onApply={({ startDate, endDate }, $event) => {
              setFilter([startDate, endDate]);
            }}
            onCancel={(event, picker) => {
              picker.element.val('');
              setFilter(undefined);
            }}
            setFilter={setFilter}
            inputClass="custom-datepicker"
          />
        </>
      );
    case 'toDate':
      return filterValue?.[0] && filterValue?.[1] ? (
        <>
          <TooltipOnHover
            dataStyle={{ color: '#005dff', cursor: 'pointer' }}
            msg={`${toLocalDate(filterValue[0])} to ${toLocalDate(filterValue[1])}`}>
            <CustomDateRangePicker
              parentEl="#orderDate"
              id="orderDate"
              initialSettings={{
                autoUpdateInput: false,
                locale: {
                  cancelLabel: 'Clear',
                },
                maxDate: new Date(),
                alwaysShowCalendars: false,
              }}
              startDate={filterValue ? filterValue[0] : ''}
              endDate={filterValue ? filterValue[1] : ''}
              placeholder="Select"
              onApply={({ startDate, endDate }, $event) => {
                setFilter([startDate, endDate]);
              }}
              onCancel={(event, picker) => {
                picker.element.val('');
                setFilter(undefined);
              }}
              setFilter={setFilter}
              inputClass="custom-datepicker"
            />
          </TooltipOnHover>
        </>
      ) : (
        <>
          <CustomDateRangePicker
            parentEl="#orderDate"
            id="orderDate"
            initialSettings={{
              autoUpdateInput: false,
              locale: {
                cancelLabel: 'Clear',
              },
              maxDate: new Date(),
              alwaysShowCalendars: false,
            }}
            startDate={filterValue ? filterValue[0] : ''}
            endDate={filterValue ? filterValue[1] : ''}
            placeholder="Select"
            onApply={({ startDate, endDate }, $event) => {
              setFilter([startDate, endDate]);
            }}
            onCancel={(event, picker) => {
              picker.element.val('');
              setFilter(undefined);
            }}
            setFilter={setFilter}
            inputClass="custom-datepicker"
          />
        </>
      );
    case 'fulfillmentYear':
      return (
        <DropdownSelect
          options={account?.financialYears}
          getOptionLabel={(option) => `${option?.label}`}
          getOptionValue={(option) => `${option?.value}`}
          noOptionsMessage={() => 'Options not found!'}
          onChange={(e) => {
            setFilter(e?.value);
          }}
          value={filterValue || ''}
          className="column-filter-dropdown"
          placeholder="Select"
          isClearable={true}
        />
      );
    case 'status':
      return (
        <>
          {[BRAND_WO_CARD_KEYS.TOTAL, BRAND_WO_CARD_KEYS.DRAFT_PRICING].includes(
            urlParams?.activeCard
          ) ? (
            <MultipleCheckbox
              options={options}
              selectedValues={filterValue}
              onChange={(selectedOptions) => {
                setFilter(selectedOptions);
              }}
            />
          ) : (
            <DropdownSelect
              options={
                [BRAND_WO_CARD_KEYS.COMPLETEDCLOSED].includes(urlParams?.activeCard)
                  ? WO_STATUSES.filter((status) =>
                      [
                        BRAND_WO_CARD_KEYS.COMPLETED,
                        BRAND_WO_CARD_KEYS.CLOSED,
                        BRAND_WO_CARD_KEYS.COMPLETEDCLOSED,
                      ].includes(status?.id)
                    )
                  : urlParams?.activeCard === BRAND_WO_CARD_KEYS.DRAFT_PRICING
                  ? WO_STATUSES.filter((status) =>
                      [
                        BRAND_WO_CARD_KEYS.DRAFT,
                        BRAND_WO_CARD_KEYS.PRICING_REVIEW,
                        BRAND_WO_CARD_KEYS.PRICING_APPROVED,
                      ].includes(status.id)
                    )
                  : vertical !== VERTICALS_MAP.PLASTIC
                  ? WO_STATUSES.filter((status) =>
                      [
                        BRAND_WO_CARD_KEYS.IN_PROGRESS,
                        BRAND_WO_CARD_KEYS.CLOSED,
                        BRAND_WO_CARD_KEYS.DRAFT,
                      ].includes(status?.id)
                    )
                  : WO_STATUSES.filter(
                      (status) =>
                        status?.id !== BRAND_WO_CARD_KEYS.APPROVED &&
                        status?.id !== BRAND_WO_CARD_KEYS.COMPLETEDCLOSED
                    )
              }
              getOptionLabel={(option) => `${option?.label}`}
              getOptionValue={(option) => `${option?.value}`}
              noOptionsMessage={() => 'Options not found!'}
              onChange={(e) => {
                activeCard === BRAND_WO_CARD_KEYS.COMPLETEDCLOSED && !e?.value
                  ? setFilter(BRAND_WO_CARD_KEYS.COMPLETEDCLOSED)
                  : setFilter(e?.value);
              }}
              value={filterValue || ''}
              className="column-filter-dropdown"
              placeholder="Select"
              isClearable={true}
            />
          )}
        </>
      );

    case 'spocID':
      return (
        <DropdownSelect
          options={uniqueClients.map((option) => ({
            ...option,
            spocID: option.spocName === 'SPOC not assigned' ? -999 : option.spocID,
          }))}
          getOptionLabel={(option) => `${option?.spocName}`}
          getOptionValue={(option) => `${option?.spocID}`}
          noOptionsMessage={() => 'Options not found!'}
          onChange={(e) => {
            setFilter(e?.spocID);
          }}
          value={filterValue ? filterValue : ''}
          className="column-filter-dropdown"
          placeholder="Select"
          isClearable={true}
          valueKey="spocID"
        />
      );
    case 'poDate':
      return filterValue?.[0] && filterValue?.[1] ? (
        <>
          <TooltipOnHover
            dataStyle={{ color: '#005dff', cursor: 'pointer' }}
            msg={`${toLocalDate(filterValue[0])} to ${toLocalDate(filterValue[1])}`}>
            <CustomDateRangePicker
              parentEl="#orderDate"
              id="orderDate"
              initialSettings={{
                autoUpdateInput: false,
                locale: {
                  cancelLabel: 'Clear',
                },
                maxDate: new Date(),
                alwaysShowCalendars: false,
              }}
              startDate={filterValue ? filterValue[0] : ''}
              endDate={filterValue ? filterValue[1] : ''}
              placeholder="Select"
              onApply={({ startDate, endDate }, $event) => {
                setFilter([startDate, endDate]);
              }}
              onCancel={(event, picker) => {
                picker.element.val('');
                setFilter(undefined);
              }}
              setFilter={setFilter}
              inputClass="custom-datepicker"
            />
          </TooltipOnHover>
        </>
      ) : (
        <>
          <CustomDateRangePicker
            parentEl="#orderDate"
            id="orderDate"
            initialSettings={{
              autoUpdateInput: false,
              locale: {
                cancelLabel: 'Clear',
              },
              maxDate: new Date(),
              alwaysShowCalendars: false,
            }}
            startDate={filterValue ? filterValue[0] : ''}
            endDate={filterValue ? filterValue[1] : ''}
            placeholder="Select"
            onApply={({ startDate, endDate }, $event) => {
              setFilter([startDate, endDate]);
            }}
            onCancel={(event, picker) => {
              picker.element.val('');
              setFilter(undefined);
            }}
            setFilter={setFilter}
            inputClass="custom-datepicker"
          />
        </>
      );
    case 'poDocStatus':
      return (
        <DropdownSelect
          options={poOptions}
          getOptionLabel={(option) => `${option?.name}`}
          getOptionValue={(option) => `${option?.id}`}
          noOptionsMessage={() => 'Options not found!'}
          onChange={(e) => {
            setFilter(e?.id);
          }}
          value={filterValue || ''}
          className="column-filter-dropdown"
          placeholder="Select"
          isClearable={true}
        />
      );
    default:
      return;
  }
}

function ColumnFilters({ column }) {
  const { filterValue, setFilter, vertical, activeCard } = column;
  const { clients } = useSelector(({ account }) => ({
    clients: account.clients,
  }));

  const uniqueClients = clients.filter(
    (client, index, self) => index === self.findIndex((c) => c.spocName === client.spocName)
  );
  const account = useSelector(({ account }) => account);
  const urlParams = parseQueryParams(window.location.search);

  const STATUS_FILTERS = {
    DRAFT_PRICING: ['DRAFT', 'PRICING_REVIEW', 'PRICING_APPROVED'],
    NON_PLASTIC: ['IN_PROGRESS', 'CLOSED', 'DRAFT'],
    PLASTIC_EXCLUDED: ['APPROVED', 'COMPLETEDCLOSED'],
  };
  const options = useMemo(() => {
    const selectedStatuses =
      urlParams?.activeCard === 'DRAFT_PRICING'
        ? STATUS_FILTERS.DRAFT_PRICING
        : vertical !== 'PLASTIC'
        ? STATUS_FILTERS.NON_PLASTIC
        : WO_STATUSES.filter((status) => !STATUS_FILTERS.PLASTIC_EXCLUDED.includes(status?.id)).map(
            (status) => status.id
          );

    return WO_STATUSES.filter((status) => selectedStatuses.includes(status?.id)).map((status) => ({
      label: status.label,
      value: status.id,
    }));
  }, [urlParams?.activeCard, vertical]);

  return (
    <span>
      {renderColumn(
        column?.id,
        filterValue,
        setFilter,
        clients,
        account,
        vertical,
        uniqueClients,
        activeCard,
        urlParams,
        options
      )}
    </span>
  );
}

export default ColumnFilters;

import BootstrapTreeTable from 'bootstrap-react-treetable';
import classNames from 'classnames';
import { groupBy } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import EmptyIcon from '../../../assets/img/empty-table.svg';
import '../../../features/Execution/ExecutionTargetList/ExecutionTargetList.scss';
import { Loader } from '../../../shared/Components';
import { useAuthority } from '../../../shared/Components/HasAuthority/HasAuthorityNew';
import { AUTHORITY2 } from '../../../shared/Constants/Constants';
import { formatIndianNumber, roundOffAmount } from '../../../shared/Utils/Utils';

const ExecutionTargetTable = (props) => {
  const { hasAuth } = useAuthority();
  const { view, filters, loading } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const [columns, setColumns] = useState([]);
  const [subHeader, setSubHeader] = useState([]);
  const [tableControls, setTableControls] = useState({});
  const isBrand = hasAuth([AUTHORITY2.BRAND]);
  const { fixedColumnsLeft, vendorColumns, clientColumns, totalQuantityColumns } = props?.columns;
  let tableControlsInit = {
    visibleRows: 1,
    allowSorting: false,
    showExpandCollapseButton: false,
    showPagination: false,
    initialRowsPerPage: 100000,
    showResetSortingButton: false,
  };

  const buildData = (data) => {
    return data?.map((categoryData) => {
      const category = categoryData?.category;
      // ==='BASE' ? 'Total Target' : categoryData?.category ;
      const categoryBaseValues = categoryData?.baseValues;
      const totalQuantity = categoryData?.totalQuantity;
      const materialGrouped = categoryData?.material?.map((materialData) => {
        const materialName = materialData?.name;
        const materialBaseValues = materialData?.baseValues;
        const materialTotalQuantity = materialData?.totalQuantity;

        if (!Array.isArray(materialBaseValues)) {
          return {};
        }

        const groupedBaseValues = groupBy(materialBaseValues, 'traceability');
        const creditsDocuments = {
          endOfLife: 0,
          recycling: 0,
        };
        const onlyCredits = {
          endOfLife: 0,
          recycling: 0,
        };
        (groupedBaseValues[true] || []).forEach((baseValue) => {
          if (baseValue?.creditType === 'END_OF_LIFE') {
            creditsDocuments.endOfLife = baseValue.value;
          } else if (baseValue.creditType === 'RECYCLING') {
            creditsDocuments.recycling = baseValue.value;
          }
        });

        (groupedBaseValues[false] || []).forEach((baseValue) => {
          if (baseValue?.creditType === 'END_OF_LIFE') {
            onlyCredits.endOfLife = baseValue.value;
          } else if (baseValue.creditType === 'RECYCLING') {
            onlyCredits.recycling = baseValue.value;
          }
        });

        const statesData = materialData?.states?.map((stateData) => {
          const groupedStateBaseValues = groupBy(stateData?.baseValues, 'traceability');

          const stateCreditsDocuments = {
            endOfLife: 0,
            recycling: 0,
          };
          const stateOnlyCredits = {
            endOfLife: 0,
            recycling: 0,
          };
          (groupedStateBaseValues[true] || []).forEach((baseValue) => {
            if (baseValue.creditType === 'END_OF_LIFE') {
              stateCreditsDocuments.endOfLife = baseValue.value;
            } else if (baseValue.creditType === 'RECYCLING') {
              stateCreditsDocuments.recycling = baseValue.value;
            }
          });

          (groupedStateBaseValues[false] || []).forEach((baseValue) => {
            if (baseValue.creditType === 'END_OF_LIFE') {
              stateOnlyCredits.endOfLife = baseValue.value;
            } else if (baseValue.creditType === 'RECYCLING') {
              stateOnlyCredits.recycling = baseValue.value;
            }
          });

          return {
            data: {
              name: stateData?.name,
              creditsDocuments: stateCreditsDocuments,
              onlyCredits: stateOnlyCredits,
              totalQuantity: stateData?.totalQuantity || 0,
              isParent: true,
            },
            children: [],
          };
        });

        return {
          data: {
            name: materialName,
            creditsDocuments,
            onlyCredits,
            totalQuantity: materialTotalQuantity || 0,
            isParent: true,
          },
          children: statesData,
        };
      });

      return {
        data: {
          name: category,
          creditsDocuments: {
            endOfLife:
              categoryBaseValues
                ?.filter((baseValue) => baseValue?.traceability)
                ?.find((baseValue) => baseValue?.creditType === 'END_OF_LIFE')?.value || 0,
            recycling:
              categoryBaseValues
                ?.filter((baseValue) => baseValue?.traceability)
                ?.find((baseValue) => baseValue?.creditType === 'RECYCLING')?.value || 0,
          },
          onlyCredits: {
            endOfLife:
              categoryBaseValues
                ?.filter((baseValue) => !baseValue?.traceability)
                ?.find((baseValue) => baseValue?.creditType === 'END_OF_LIFE')?.value || 0,
            recycling:
              categoryBaseValues
                ?.filter((baseValue) => !baseValue?.traceability)
                ?.find((baseValue) => baseValue?.creditType === 'RECYCLING')?.value || 0,
          },
          totalQuantity: totalQuantity || 0,
          isParent: true,
        },
        children: materialGrouped,
      };
    });
  };

  const memoizedData = useMemo(() => {
    return buildData(props?.data);
  }, [props?.data]);

  const updateTableControls = (filters) => {
    let visibleRows = 1;

    if (
      (filters.stateIds && filters.itemIds) ||
      (filters.stateIds && filters.itemIds && filters?.clientId)
    ) {
      visibleRows = 3;
    } else if (filters.itemIds) {
      visibleRows = 2;
    } else if (filters.stateIds) {
      visibleRows = 3;
    } else if (filters?.clientId) {
      visibleRows = 3;
    } else {
      visibleRows = 1;
    }
    setTableControls({ ...tableControlsInit, visibleRows });
  };
  useEffect(() => {
    let col = [];
    let _extraHeader = null;
    col = [...fixedColumnsLeft, ...clientColumns, ...vendorColumns, ...totalQuantityColumns];
    _extraHeader = !isBrand ? (
      <>
        <tr className="">
          <th colSpan={0} style={{ width: '20%', background: '#EAECF0', borderBottom: '0px' }}></th>
          <th colSpan={5} className="main_title_unplanned">
            Unplanned Target (MT) : {formatIndianNumber(props?.totalQuantity) || 0}
          </th>
        </tr>
        <tr>
          <th colSpan={0} style={{ width: '20%', background: '#EAECF0', borderTop: '0px' }}></th>
          <th className="credits_docs" colSpan={2}>
            Credits + Documents
          </th>
          <th className="only_credits_docs" colSpan={2}>
            Only Credits
          </th>
          <th colSpan={0} style={{ background: '#EAECF0', borderTop: '0px' }}></th>
        </tr>
      </>
    ) : null;

    setSubHeader(_extraHeader);
    setColumns(col);
    updateTableControls(filters);
  }, [filters, props?.totalQuantity]);

  const buildTotal = (e) => {
    return buildData(props.data)
      .map((row) => row?.data[e])
      ?.reduce((prev, next) => roundOffAmount(prev + next), 0);
  };

  const comboTotal = (tgt1, tgt2, tgt3) => {
    return buildTotal(tgt1) + buildTotal(tgt2) + buildTotal(tgt3);
  };

  return (
    <div className="ExecutionTargetTable my-4">
      {!props?.loading && memoizedData && memoizedData?.length > 0 ? (
        <div
          className={classNames('animated fadeIn', {
            'no-colors':
              isBrand ||
              !(
                (filters.clientId != null && filters.serviceProviderId != null) ||
                (!filters.clientId && !filters.serviceProviderId)
              ),
          })}>
          {
            <>
              <BootstrapTreeTable
                columns={columns}
                className="mb-0 my-4"
                extraHeader={subHeader}
                tableData={buildData(props.data)}
                control={tableControls}
              />
            </>
          }
        </div>
      ) : (
        <Row>
          {props?.loading ? (
            <Loader />
          ) : (
            <div className="text-center mx-auto mt">
              <img width="150" src={EmptyIcon} />
              <>
                <h5 className="py-3">
                  There is no data for current applied filter. Please change or clear the filter!
                </h5>
                <Button
                  variant={'btn-primary'}
                  onClick={props?.clearFilters}
                  className="mx-auto btn-primary px-3">
                  Clear Filter
                </Button>
              </>
            </div>
          )}
        </Row>
      )}
    </div>
  );
};
export default ExecutionTargetTable;

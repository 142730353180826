import { Link } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Accordion, Card, Col, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import CloseIcon from '../../../assets/imageComponents/CloseIcon';
import UploadArrowIcon from '../../../assets/imageComponents/UploadArrowIcon';
import { ReactComponent as AccordianArrow } from '../../../assets/img/AccordianArrow.svg';
import { uploadDocument } from '../../Utils/FileUpload';
import './ScrollableUpload.css';

function MultiUploadCard(props) {
  const {
    documents = [],
    handleDocChange,
    deletDoc,
    isEdit = true,
    isPartialPlanned,
    isPlanned,
    isFulfilled,
    isProgress,
    isFulfillmentStarted,
    status,
    initialDocuments,
  } = props;

  const [uploadStatus, setUploadStatus] = useState([]);
  const [inproDoc, setInProDoc] = useState([]);
  const [isInnerArrowActive, setIsInnerArrowActive] = useState({
    isArrowActive: true,
    activeArrowValue: null,
  });
  useEffect(() => {
    if (status && documents && documents?.length > 0) {
      let findType = documents?.map((item) => item.type);

      if (findType && findType?.length > 0 && findType[0] === 'WO_PURCHASE_ORDER') {
        setIsInnerArrowActive({ isArrowActive: true, activeArrowValue: 1 });
      } else if (findType && findType?.length > 0 && findType[0] === 'WO_BRAND_AGREEMENT') {
        setIsInnerArrowActive({ isArrowActive: true, activeArrowValue: 2 });
      } else if (findType && findType?.length > 0 && findType[0] === 'WO_OTHERS') {
        setIsInnerArrowActive({ isArrowActive: true, activeArrowValue: 3 });
      }
    }
  }, [status]);

  const DOCUMENTS_UPLOAD = [
    {
      id: 1,
      label: 'Purchase Order',
      fileName: 'Purchase_order',
      type: 'WO_PURCHASE_ORDER',
      entity: 'WORK_ORDER',
      required: true,
      disabled:
        isEdit &&
        isProgress &&
        initialDocuments?.filter((doc) => doc?.type === 'WO_PURCHASE_ORDER').length !== 0,
    },
    {
      id: 2,
      label: 'Signed Brand Agreement',
      fileName: 'Signed_brand_agreement',
      type: 'WO_BRAND_AGREEMENT',
      entity: 'WORK_ORDER',
      disabled:
        isEdit &&
        isProgress &&
        initialDocuments?.filter((doc) => doc?.type === 'WO_BRAND_AGREEMENT').length !== 0,
    },
    {
      id: 3,
      fileName: 'Work_order_Others',
      label: 'Others',
      type: 'WO_OTHERS',
      entity: 'WORK_ORDER',
      disabled:
        isEdit &&
        isProgress &&
        isFulfillmentStarted &&
        initialDocuments?.filter((doc) => doc?.type === 'WO_OTHERS').length !== 0,
    },
  ];

  useEffect(() => {
    if (inproDoc?.length) {
      const docs = inproDoc.filter((e) => e?.status === 'DONE');
      handleDocChange(docs);
      setInProDoc([]);
    }
  }, [inproDoc?.length]);

  const uploadDocumentToS3 = (file, delDocId, type, count, index) => {
    const fileDetails = {
      active: true,
      entity: 'WORK_ORDER',
      fileName: file.name,
      fileSize: file.size,
      contentType: file.type,
      type: type,
    };
    let docTobeUploaded = { status: '', fileName: '' };

    const signedURLCallback = (doc) => {
      docTobeUploaded = { id: delDocId, ...doc };
    };

    const successCallback = (signedURLResponse) => {
      docTobeUploaded.status = 'DONE';
      setInProDoc((oldObj) => [...oldObj, docTobeUploaded]);
      toast.success('document uploaded successfully');
      setIsInnerArrowActive({
        activeArrowValue:
          type === 'WO_BRAND_AGREEMENT'
            ? 2
            : type === 'WO_PURCHASE_ORDER'
            ? 1
            : type === 'WO_OTHERS'
            ? 3
            : null,
        isArrowActive: true,
      });
    };

    const failureCallback = (error) => {
      setUploadStatus({
        ...uploadStatus,
        [docTobeUploaded?.fileName]: {
          status: 'FAILED',
          uploading: false,
          progress: 0,
        },
      });
      // if(docTobeUploaded){
      docTobeUploaded.status = 'FAIL';
      // }
      // setInProDoc((oldObj) => [...(oldObj || []), docTobeUploaded]);
      toast.error('Failed to upload the document');
    };

    uploadDocument(fileDetails, file, signedURLCallback, successCallback, failureCallback);
  };

  const handleFileSelected = (files, docId, type, id) => {
    // eslint-disable-next-line no-unused-expressions
    Object.keys(files)?.forEach((file, index) =>
      uploadDocumentToS3(files[file], docId, type, files.length, index)
    );
    document.getElementById(id).value = '';
  };

  const rotateInnerArrow = (value, event) => {
    setIsInnerArrowActive((prevState) => ({
      ...prevState,
      isArrowActive: isInnerArrowActive?.activeArrowValue === value?.id ? false : true,
      activeArrowValue: isInnerArrowActive?.activeArrowValue === value?.id ? null : value?.id,
    }));
  };

  return (
    <div>
      <div>
        <p className="cardHead">Uploaded Documents</p>
        <p className="uploadaccepttext">Jpeg, Png, Pdf., Max. file size 10 MB</p>
      </div>
      <div className="documents_upload_div">
        {DOCUMENTS_UPLOAD?.map((value) => {
          return (
            <Accordion
              defaultActiveKey={false}
              activeKey={
                isInnerArrowActive?.isArrowActive
                  ? isInnerArrowActive?.activeArrowValue?.toString()
                  : null
              }>
              <>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey={(value?.id).toString()}
                  style={value?.disabled ? { backgroundColor: '#F2F4F7' } : {}}
                  className="innerAccordian accordion_toggle_header"
                  activeKey={
                    isInnerArrowActive?.isArrowActive
                      ? isInnerArrowActive?.activeArrowValue?.toString()
                      : null
                  }
                  onClick={(event) => rotateInnerArrow(value, event)}>
                  <Row>
                    <Col>
                      <p className="accordion_header_text">
                        {value.label}
                        {value.required && <span style={{ color: 'red' }}>*</span>}{' '}
                      </p>
                    </Col>
                    <Col className="col-xs-auto col-md-auto col-lg-auto col-sm-auto d-flex justify-content-end align-items-center gap-4">
                      {isEdit && !value?.disabled && (
                        <div>
                          <label
                            htmlFor={`file-upload-${value?.id + 111}`}
                            style={{ marginRight: '5px' }}>
                            <UploadArrowIcon />
                          </label>
                          <input
                            accept="application/pdf,image/png,image/jpeg"
                            type="file"
                            disabled={value?.disabled ? true : false}
                            id={`file-upload-${value?.id + 111}`}
                            style={{ display: 'none' }}
                            hidden
                            multiple
                            onChange={(e) =>
                              handleFileSelected(
                                e.target.files,
                                null,
                                value?.type,
                                `file-upload-${value?.id + 111}`
                              )
                            }
                          />
                        </div>
                      )}

                      <div className="ml-4 AccordianArrow">
                        <AccordianArrow
                          style={{
                            transform:
                              isInnerArrowActive?.isArrowActive &&
                              isInnerArrowActive?.activeArrowValue === value?.id &&
                              `rotate(-180deg)`,
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                </Accordion.Toggle>
                <Accordion.Collapse
                  className="docums_div"
                  style={value?.disabled ? { backgroundColor: '#F2F4F7' } : {}}
                  eventKey={(value?.id).toString()}
                  activeKey={
                    isInnerArrowActive?.isArrowActive
                      ? isInnerArrowActive?.activeArrowValue?.toString()
                      : null
                  }>
                  <Card.Body style={{ height: '70px' }}>
                    <div>
                      {documents?.length ? (
                        <div className="multi_uploadcardnew_docum_div_inner">
                          {documents?.map((document, id) => {
                            const groupedDocuments = documents?.reduce((acc, doc) => {
                              if (!acc[doc?.type]) {
                                acc[doc?.type] = [];
                              }
                              acc[doc?.type].push(doc);
                              return acc;
                            }, {});
                            const documentType = document?.type;
                            const currentGroup = groupedDocuments[documentType];
                            const localIndex =
                              currentGroup.findIndex((doc) => doc?.id === document?.id) + 1;

                            return (
                              <div key={id + 444} className="my-2">
                                <div>
                                  {value?.type === document?.type && (
                                    <Row>
                                      <Col xs={1} />
                                      <Col xs={7}>
                                        <Link
                                          className="linkdocumentmulti"
                                          key={id + 222}
                                          target="_blank"
                                          href={`${document?.viewUrl}`}
                                          underline="always">
                                          {document?.type && value?.fileName
                                            ? `${value?.fileName}_${localIndex}`
                                            : `Document_${id + 1}`}
                                        </Link>
                                      </Col>
                                      <Col xs={3}>
                                        {isEdit && !value?.disabled && (
                                          <div
                                            style={{ marginRight: '6px', cursor: 'pointer' }}
                                            onClick={
                                              value?.disabled ? () => {} : () => deletDoc(document)
                                            }>
                                            {/* <DeleteICon /> */}
                                            <CloseIcon strokeColor="#D92D20" />
                                          </div>
                                        )}
                                      </Col>
                                    </Row>
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      ) : null}
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </>
            </Accordion>
          );
        })}
      </div>
    </div>
  );
}

export default MultiUploadCard;

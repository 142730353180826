import { faChevronLeft, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormik } from 'formik';
import { isEmpty, isNull, sum } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Badge, Button, Card, Col, Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { StringParam, useQueryParam } from 'use-query-params';
import * as Yup from 'yup';
import AccessDenied from '../../../features/Others/AccessDenied/AccessDenied';
import BrandWorkOrderItemsEdit from '../../../features/WorkOrder/WorkOrderItemsEdit/BrandWorkOrderItemsEdit';
import { WorkOrderService } from '../../../features/WorkOrder/workOrder.service';
import { useAccount, useAsyncEffect } from '../../../hooks';
import { useServiceProviders } from '../../../hooks/useCustomers';
import { useBrowseNodeMap, useMaterialCategory } from '../../../hooks/useMaterialCategory';
import { useRegions } from '../../../hooks/useRegions';
import { DropdownSelect, RcylDatePicker } from '../../../shared/Components';
import { useAuthority } from '../../../shared/Components/HasAuthority/HasAuthorityNew';
import Loader from '../../../shared/Components/Loader/Loader';
// import UploadScrollableCard from '../../../shared/Components/UploadScrollableCard/UploadScrollableCard';
import moment from 'moment';
import ArrowUpRight from '../../../assets/imageComponents/arrow-up-right';
import { getClientsById } from '../../../features/Customers/api_calls';
import CommentsModal from '../../../features/WorkOrder/WorkOrderItemsEdit/CommentsModal';
import PromptInputModal from '../../../features/WorkOrder/WorkOrderItemsEdit/PromptInputModal';
import { useFinancialYear } from '../../../hooks/useFinancialYear';
import PromptModal from '../../../shared/Components/PromptModal/PromptModal';
import MultiUploadCard from '../../../shared/Components/UploadScrollableCard/MultiUploadCardNew';
import {
  AUTHORITY2,
  EBT_BROWSE_NODE_IDS,
  ENTITY_TYPE_OPTIONS,
  FULFILLMENT_CYCLES,
  PLASTIC_BROWSE_NODE_IDS,
  TRACEABILITY_DOCS_OPTIONS,
  WORK_ORDER_DISPLAY_STATUS,
} from '../../../shared/Constants/Constants';
import { toLocalDate, toServerDate } from '../../../shared/Utils/Helper';
import { roundOffAmount } from '../../../shared/Utils/Utils';
import BrandPricingModal from '../../MarginApproval/BrandPricingModal';
import '../../MarginApproval/MarginApproval.scss';
import { MarginApproval } from '../../MarginApproval/marginApproval.service';
import POModal from './PoModal';
import './WorkOrderCreate.scss';
import { validateAndReturnInvalidRowIndexes } from './WorkOrderCreateFormValidators';

const checkSpecificValuesNotEmptyInArray = (arr, fields) => {
  return arr.every((obj) =>
    fields.every((field) => obj[field] !== undefined && obj[field] !== null && obj[field] !== '')
  );
};
const WorkOrderCreateBrandPRO = (props) => {
  const workOrderId = props.match.params.id;
  const { itemsById } = useBrowseNodeMap();
  const account = useAccount();

  let [isEdit] = useQueryParam('isEdit', StringParam);

  const history = useHistory();
  const { hasAuth } = useAuthority();
  const { hasPermission } = useAuthority();

  const isUserServiceProvider = hasAuth([AUTHORITY2.SERVICE_PROVIDER, AUTHORITY2.PRO]);
  const isUserPRO = hasAuth([AUTHORITY2.PRO]);
  const isBrand = hasAuth([AUTHORITY2.BRAND]);
  const [brandDetails, setBrandDetails] = useState({});
  const [loadingBrandDetails, setLoadingBrandDetails] = useState(false);
  const [maxValueByCategory, setMaxValueByCategory] = useState({
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    6: 0,
    7: 0,
  });
  const [cpcbWalletNumbers, setCpcbWalletNumbers] = useState([]);
  const [vendorPricing, setVendorPricing] = useState([]);
  const [showPromptModal, setShowPromptModal] = useState(false);
  const [showError, setShowError] = useState(true);
  const [prices, setPrices] = useState([]);
  const [currentMarginUnmet, setCurrentMarginUnmet] = useState(false);
  const [isMarginCompared, setIsMarginCompared] = useState(false);
  const [initialDisplay, setInitialDisplay] = useState(false);
  const NEW_ROW = {
    workOrderFor: null,
    browseNodeId: null,
    itemId: null,
    stateId: null,
    districtId: null,
    pricePerUnit: null,
    lineTotal: '',
    qty: 0,
    estimatedDelivery: '',
    fulfillmentCycle: '',
    unallocatedTarget: '',
    itemUnit: '',
    poLineItem: account?.user?.profile?.id === 9053 ? '' : undefined,
    sac: null,
    taxRate: null,
    plannedQty: 0,
    fulfilledQty: 0,
  };
  const [renderCount, setRenderCount] = useState(0);
  const [rowsToHighlight, setRowsHighlight] = useState([]);
  const [updateSaveInProgress, setUpdateSaveInProgress] = useState(false);
  const [deliveryExists, setDeliveryExists] = useState(false);
  const [originalWorkOrderData, setOriginalWorkOrderData] = useState({});
  const [previousMarginData, setPreviousMarginData] = useState({});
  const [poDate, setPoDate] = useState('');
  const [commentValidation, setCommentValidation] = useState();
  const [orderSummary, setOrderSummary] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [uploadedDocs, setUploadedDocs] = useState();
  const [isLoading, setIsLoading] = useState(isEdit);
  const [showModal, setShowModal] = useState(false);
  const [brandPricingList, setBrandPricingList] = useState();

  const [marginMeet, setMarginMeet] = useState(true);
  const [profitMarginMet, setProfitMarginMet] = useState(false);
  const [showPOModal, setShowPOModal] = useState(false);
  const [submitForInreviewClicked, setSubmitForInreviewClicked] = useState(false);
  const [saveEditedDetails, setSaveEditedDetails] = useState(false);
  const [SaveEditText, setSaveEditText] = useState('');
  const [goBack, setGoBack] = useState(false);
  const [updateTableData, setUpdateTableData] = useState(false);
  const serviceProviders = useServiceProviders(true);
  const [deletedRows, setDeletedRows] = useState([]);
  const { clients, financialYears } = useSelector(({ account }) => {
    return { clients: account.clients, financialYears: account?.financialYears };
  });
  const browseNodes = useMaterialCategory();
  const regions = useRegions();
  const [wholeWoMargin, setWholeWoMargin] = useState();
  const [prevMargin, setPrevMargin] = useState();
  const [isAllowedForReview, setIsAllowedForReview] = useState(false);

  useEffect(() => {
    if (workOrderId) {
      doGetWorkOrderByID();
    }
    setRenderCount(renderCount + 1);
  }, []);

  useEffect(() => {
    fetchBrandPrices();
  }, []);

  const year = useFinancialYear();
  const financialYearEndDate =
    year?.currentYear?.value?.toDate || moment().endOf('year').format('YYYY-MM-DD');

  const doGetWorkOrderByID = async () => {
    try {
      const response = await WorkOrderService.fetchWorkOrderById(workOrderId);
      if (response?.data) {
        setPreviousMarginData(response?.data);
        if (isEdit) {
          response.data.workOrderItems.forEach(function (item) {
            item.fulQty = item.fulfilledQty;
            if (item.fulfilledQty !== null && item.fulfilledQty > 0) {
              setDeliveryExists(true);
            }
          });
        }
        const maxValueMap = {
          1: 0,
          2: 0,
          3: 0,
          4: 0,
          5: 0,
          6: 0,
          7: 0,
        };
        response.data.workOrderItems.forEach((li) => {
          maxValueMap[li.browseNodeId] += li.qty;
        });
        setMaxValueByCategory(maxValueMap);

        setOriginalWorkOrderData({
          ...response?.data,
          fulfilledYear: getFulFillyearLabel(response?.data?.fulfillmentYearStartDate),
          fulfillmentCycle: response?.data?.workOrderItems?.[0]?.fulfillmentCycle,
          workOrderItems: response?.data?.workOrderItems?.map((wo_item) => ({
            ...wo_item,
            stateCode: wo_item?.stateCode !== null ? wo_item?.stateCode : 0,
            stateId: wo_item?.stateId !== null ? wo_item?.stateId : 0,
          })),
        });
      }
    } catch (e) {}
    setIsLoading(false);
  };

  function isValidEntity(value) {
    if (isEmpty(brandDetails)) return true;
    if (!brandDetails?.customerPiboDetails?.length) return false;
    const currentPibo = brandDetails?.customerPiboDetails
      ?.filter((piboObject) => piboObject?.active)
      ?.filter((piboObject) => piboObject.type === value?.replace(' ', '_')?.toUpperCase())
      ?.at(0);
    if (!currentPibo?.cpcbWalletNumber) {
      return false;
    }
    return true;
  }

  const workOrderForm = useFormik({
    enableReinitialize: isEdit,
    initialValues: {
      fromCustomerId: isBrand ? account?.user?.profile?.id : null,
      fromDate: '',
      toCustomerId: '',
      customerType: 'EPR',
      entityType: '',
      cpcbWalletNumber: '',
      traceabilityDocuments: null,
      toBrandId: '',
      brandId: '',
      toDate: '',
      poNumber: '',
      poDate: '',
      paymentTerm: null,
      draft: false,
      isSaveButtonDisabled: false,
      workOrderItems: [NEW_ROW],
      documents: [],
      poDocuments: [],
      fulfilledYear: '',
      fulfillmentYearStartDate: '',
      fulfillmentYearEndDate: '',
      comments: '',
      isInvoicing: null,
      extraComments: '',
      ...originalWorkOrderData,
    },
    validationSchema: Yup.object({
      fromDate: Yup.string('Invalid date.').required('Start date required.'),
      comments: Yup.string()
        .nullable()
        .test(
          'invoicing-test',
          'If invoicing is required, then comments must be provided',
          function (value) {
            const { isInvoicing } = this.parent;
            if (isInvoicing) {
              return !!value;
            }
            return true;
          }
        ),
      toDate: Yup.string('Invalid date.')
        .typeError('Estimated Delivery date required')
        .required('Estimated delivery date required.'),
      poNumber: Yup.string().when('workOrderItems', {
        is: (workOrderItems) =>
          workOrderItems.some((item) => EBT_BROWSE_NODE_IDS.includes(item.browseNodeId)),
        then: (schema) => schema.required('PO Number required.'),
        otherwise: (schema) => schema.notRequired(),
      }),

      poDate: Yup.string().when('workOrderItems', {
        is: (workOrderItems) =>
          workOrderItems.some((item) => EBT_BROWSE_NODE_IDS.includes(item.browseNodeId)),
        then: (schema) => schema.required('PO Date required.'),
        otherwise: (schema) => schema.notRequired(),
      }),
      paymentTerm: Yup.number('Invalid paymentTerm.').required('Payment Term required.'),
      entityType: Yup.string('Invalid entity type')
        .required('Entity type required')
        .test(
          'entity-should-have-data',
          'Importer Details are not available. Kindly update the information',
          function (value) {
            return isValidEntity(value);
          }
        ),
      cpcbWalletNumber: Yup.string().required(),
      fulfillmentYearStartDate: Yup.string('Invalid date ').required('Fulfillment year required'),
      fulfillmentYearEndDate: Yup.string('Invalid entity type'),
      traceabilityDocuments: Yup.boolean().required('Traceability documents is required'),
      id: Yup.string().nullable(true),
      draft: Yup.boolean().nullable(true),
      createdBy: Yup.number().nullable(true),
      totalAmount: Yup.number().nullable(true),
      fromCustomerId: Yup.number().required('Brand selection required'),
      workOrderType: Yup.string().nullable(true),
      status: Yup.string().nullable(true),
      createdOn: Yup.string().nullable(true),
      modifiedOn: Yup.string().nullable(true),
      workOrderItems: Yup.array()
        .of(
          Yup.object({
            browseNodeId: Yup.string().required(),
            itemId: Yup.string().required(),
            stateId: Yup.string().nullable(),
            districtId: Yup.string().nullable(),
            pricePerUnit: Yup.number().required().moreThan(0),
            lineTotal: Yup.number().required(),
            qty: Yup.number().required().moreThan(0),
            estimatedDelivery: Yup.string(),
            unallocatedTarget: Yup.number(),
            sac: null,
          })
            .test(
              'qty-greater-equal-to-planned',
              'Quantity cannot be less than the quantity already planned',
              function (value) {
                if (!isProgress) return true;
                return value?.qty >= (value?.plannedQty ?? 0);
              }
            )
            .test(
              'does-hul-have-poLineItem',
              'PO Line item is a mandatory field',
              function (value) {
                if (
                  !(
                    account?.user?.profile?.id === 9053 ||
                    workOrderForm?.values?.fromCustomerId === 9053
                  )
                )
                  return true;
                return !!value.poLineItem;
              }
            )
            .test(
              'credit-type-mandatory-for-plastics',
              'Credit type is a mandatory field for plastics',
              function (value) {
                if (!['1', '2', '4', '6'].includes(value?.browseNodeId)) return true;
                return !!value?.creditType;
              }
            )
        )
        .min(1)
        .test(
          'not-all-fields-same',
          'Two Line Items cannot have the same values for all fields',
          function (values) {
            if (!isProgress) return true;
            const createKey = (browseNodeId, itemId, stateId, districtId, creditType) => {
              return `${browseNodeId ?? ''} ${itemId ?? ''} ${stateId ?? ''} ${districtId ?? ''} ${
                creditType ?? null
              }`;
            };
            const map = new Map();
            let isValid = true;
            values.forEach((value) => {
              const key = createKey(
                value['browseNodeId'],
                value['itemId'],
                value['stateId'],
                value['districtId'],
                value['creditType']
              );
              isValid = isValid & !map.has(key);
              map.set(key, true);
            });

            return isValid;
          }
        )
        .test(
          'sum-for-category-less-than-max',
          'The total Quantity for a Category cannot be increased',
          function (value) {
            if (!isProgress) return true;
            const sumForCategory = {
              1: 0,
              2: 0,
              3: 0,
              4: 0,
              5: 0,
              6: 0,
              7: 0,
            };
            value.forEach((li) => (sumForCategory[li.browseNodeId] += li.qty));
            let isValid = true;
            Object.keys(sumForCategory).forEach((nodeId) => {
              isValid = isValid & (sumForCategory[nodeId] === maxValueByCategory[nodeId]);
            });
            return isValid;
          }
        ),
      documents: Yup.array().when('workOrderItems', {
        is: (workOrderItems) =>
          workOrderItems.some((item) => ['3', '5', '7'].includes(item.browseNodeId)),
        then: (schema) => schema.min(1, 'At least one document is required'),
        otherwise: (schema) => schema.notRequired(),
      }),
      isInvoicing: Yup.boolean().nullable().required('Please select an invoicing option'),
      extraComments: Yup.string(),
      ...commentValidation,
    }),
    isInitialValid: false,
    onSubmit: (values) => {
      prepareWorkOrderData({
        ...values,
      });
    },
  });

  useEffect(() => {
    if (workOrderForm.values?.fromCustomerId && workOrderForm.values?.entityType) {
      getCpcbWalletInfo();
    }
    getVendorPricing();
  }, [workOrderForm.values?.fromCustomerId, workOrderForm.values?.entityType]);

  useAsyncEffect(async () => {
    if (workOrderForm?.values?.fromCustomerId) {
      setLoadingBrandDetails(true);
      getClientsById(
        workOrderForm?.values?.fromCustomerId,
        (response) => {
          setBrandDetails(response.data);
          setLoadingBrandDetails(false);
          workOrderForm.validateForm();
        },
        (error) => {
          setLoadingBrandDetails(false);
        }
      );
    }
  }, [workOrderForm?.values?.fromCustomerId]);

  useEffect(() => {
    if (workOrderForm?.values?.status === 'PRICING_APPROVED') {
      setInitialDisplay(true);
    }
  }, [workOrderForm?.values?.status]);

  useEffect(() => {
    if (
      isEdit &&
      (workOrderForm?.values?.status === 'PRICING_APPROVED' ||
        workOrderForm?.values?.status === 'REJECTED')
    ) {
      const margin = previousMarginalculator(previousMarginData?.workOrderItems);
      setMarginMeet(false);
      // setPrevMargin(margin);
    }
  }, [workOrderForm?.values?.status]);
  useEffect(() => {
    if (renderCount) {
      workOrderForm.validateForm().then();
      setRowsHighlight(validateAndReturnInvalidRowIndexes(workOrderForm.values));
    }
  }, [workOrderForm?.values]);
  useEffect(() => {
    if (financialYears && financialYears.length > 0) {
      const today = moment();
      const selectedYear = financialYears.find(
        (year) =>
          moment(year.value.fromDate).isSameOrBefore(today) &&
          moment(year.value.toDate).isSameOrAfter(today)
      );
      if (selectedYear?.value) {
        setPoDate(selectedYear.value.fromDate);
        workOrderForm.setFieldValue('fulfilledYear', selectedYear.label);
        workOrderForm.setFieldValue('fromDate', moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'));
        workOrderForm.setFieldValue('toDate', selectedYear.value.toDate);
        workOrderForm.setFieldValue('fulfillmentYearStartDate', selectedYear.value.fromDate);
        workOrderForm.setFieldValue('fulfillmentYearEndDate', selectedYear.value.toDate);
      }
    }
  }, [financialYears]);

  useEffect(() => {
    WorkOrderService.getOrderSummary(
      account?.selectedFinancialYear?.fromDate,
      account?.selectedFinancialYear?.toDate
    ).then((os) => {
      setOrderSummary(os);
    });
  }, []);
  const lastFinancialYear = account?.financialYears?.[account.financialYears.length - 1];

  const hasPlastic = workOrderForm?.values?.workOrderItems?.some((woItem) =>
    ['1', '2', '4', '6'].includes(woItem?.browseNodeId)
  );

  const { selectedFinancialYear } = useFinancialYear();

  const getFinancialYearLabel = (startDate, endDate) => {
    const startYear = new Date(startDate).getFullYear();
    const endYear = new Date(endDate).getFullYear();
    return `April ${startYear} to March ${endYear}`;
  };
  const prepareWorkOrderData = (requestData, shouldGoBack = true) => {
    let filteredPrices = [];
    if (
      ['PRICING_APPROVED', 'DRAFT', 'REJECTED', 'IN_REVIEW'].includes(workOrderForm.values.status)
    ) {
      filteredPrices = compareRealMargin(workOrderForm?.values?.workOrderItems, false);
    }

    requestData = {
      ...requestData,
      invoicing: requestData?.isInvoicing,
      fulfilledYear: getFinancialYearLabel(
        selectedFinancialYear.fromDate,
        selectedFinancialYear.toDate
      ),
      workOrderItems: [...requestData?.workOrderItems]?.map((row, index) => {
        return {
          ...row,
          itemUnit:
            row?.itemUnit === '' || row?.itemUnit === 0 || row?.itemUnit === undefined
              ? null
              : row?.itemUnit,
          unallocatedTarget:
            row?.unallocatedTarget === '' ||
            row?.unallocatedTarget === 0 ||
            row?.unallocatedTarget === undefined
              ? null
              : row?.unallocatedTarget,
          qty: row?.qty === '' || row?.qty === 0 || row?.qty === undefined ? null : row?.qty,
          creditType:
            row?.creditType === '' ||
            row?.creditType === 'Select option' ||
            row?.creditType === undefined
              ? null
              : row?.creditType,
          fulfillmentCycle: workOrderForm?.values?.fulfillmentCycle,
          estimatedDelivery: workOrderForm?.values?.toDate,
          stateId: row?.stateId || null,
          taxRate: itemsById[row?.itemId]?.hsnCode?.tax,
          hsnCode: itemsById[row?.itemId]?.hsnCode?.id,
          idealPrice: isEdit ? filteredPrices?.[index] : prices?.[index],
          fromDate: toServerDate(requestData.fromDate),
          toDate: toServerDate(requestData.toDate),
        };
      }),

      documents:
        workOrderForm?.values?.documents && workOrderForm.values.documents.length > 0
          ? workOrderForm.values.documents
          : requestData?.poDocuments || [],
    };

    if (!isUserServiceProvider) {
      requestData = {
        ...requestData,
        customerType: workOrderForm?.values?.customerType,
        entityType: workOrderForm?.values?.entityType,
        traceabilityDocuments: workOrderForm?.values?.traceabilityDocuments,
        documents:
          workOrderForm?.values?.documents && workOrderForm.values.documents.length > 0
            ? workOrderForm.values.documents
            : requestData?.poDocuments || [],
        fromDate: toServerDate(requestData.fromDate),
        toDate: toServerDate(requestData.toDate),
      };
      requestData.workOrderItems.forEach((item, itemIndex) => {
        requestData.workOrderItems[itemIndex].workOrderFor = account?.user?.profile?.id;
      });
    }
    createOrUpdateWorkOrder(requestData, requestData.id ? false : true, shouldGoBack);
  };

  const getFulFillyearLabel = (dateVal) => {
    const fulFilledYear = account?.financialYears?.find(
      (item) => toLocalDate(item?.value?.fromDate) === toLocalDate(dateVal)
    )?.label;

    return fulFilledYear;
  };

  const createOrUpdateWorkOrder = async (requestData, createOperation, shouldGoBack = true) => {
    try {
      setIsLoading(true);
      const response = createOperation
        ? await WorkOrderService.createWorkOrder({
            ...requestData,
            toCustomerId: 8651,
            salesOrderId: originalWorkOrderData?.salesOrderId,
            salesOrderNumber: originalWorkOrderData?.salesOrderNumber,
          })
        : await WorkOrderService.updateWorkOrder({ ...requestData, toCustomerId: 8651 });
      if (response?.status === 200 || response?.status === 201) {
        workOrderForm.setValues({
          ...workOrderForm.values,
          draft: false,
          status: isProgress ? 'IN_PROGRESS' : hasPlastic ? 'IN_REVIEW' : 'IN_PROGRESS',
        });
        setIsLoading(false);
        workOrderForm.resetForm({
          values: response.data,
          fulfilledYear: getFulFillyearLabel(response?.data?.fulfillmentYearStartDate),
          fulfillmentCycle: response?.data?.workOrderItems?.[0]?.fulfillmentCycle,
        });
        if (response?.data?.draft && shouldGoBack) {
          createOperation
            ? toast.success('Work order saved as draft successfully.')
            : toast.success('Work order draft updated successfully.');
          history.goBack();
        } else if (response?.data?.draft) {
          createOperation
            ? toast.success('Work order saved as draft successfully.')
            : toast.success('Work order draft updated successfully.');
        } else {
          toast.success(
            isProgress || (isRejected && requestData?.status === 'REJECTED')
              ? 'Work order updated successfully'
              : requestData?.status === 'PRICING_APPROVED'
              ? 'Work Order Submitted for Pricing Approved'
              : hasPlastic
              ? 'Work Order Submitted for review'
              : 'Work order created successfully'
          );
        }
        if (!createOperation) {
          workOrderForm.setFieldValue('documents', [...(response?.data?.documents || [])]);
        }

        if (!response?.data?.draft) {
          history.push(`/work-orders-brandPRO/${response?.data?.id}?isCreateUpdate=true`);
        }
      }
    } catch (e) {
      setUpdateSaveInProgress(false);
      setIsLoading(false);
    }
    setUpdateSaveInProgress(false);
    setIsLoading(false);
  };
  const compareMargins = (prevMargin, currentMargin, showToast) => {
    if (prevMargin != null && currentMargin != null) {
      if (currentMargin < Number(prevMargin) && showToast) {
        toast.error('Margin does not Met');
        setCurrentMarginUnmet(true);
        setProfitMarginMet(false);
        setIsMarginCompared(true);
        setInitialDisplay(false);
        setIsAllowedForReview(false);
      } else if (currentMargin === Number(prevMargin) && showToast) {
        toast.warn('Margins are equal');
        setProfitMarginMet(true);
        setIsMarginCompared(true);
        setIsAllowedForReview(false);
        setInitialDisplay(false);
      } else {
        toast.success('Margin Meet Successfully');
        setProfitMarginMet(true);
        setIsMarginCompared(true);
        setIsAllowedForReview(false);
        setInitialDisplay(false);
      }
    }
  };

  const compareRealMargin = (data, showToast = true) => {
    if (
      workOrderForm?.values?.status === 'PRICING_APPROVED' ||
      workOrderForm?.values?.status === 'DRAFT' ||
      workOrderForm?.values?.status === 'IN_REVIEW' ||
      workOrderForm?.values?.status === 'PRICING_REVIEW' ||
      workOrderForm?.values?.status === 'REJECTED' ||
      data?.every(
        (item) =>
          item.browseNodeId && item.creditType && item.pricePerUnit && !showPromptModal && showError
      )
    ) {
      if (!isEBT && isEdit && !isProgress) {
        const { wholeWOMargin: currentMargin, filteredPrices } = validatePricingAndMargin(
          data,
          showToast
        );
        let preMargin = previousMarginalculator(previousMarginData?.workOrderItems);
        preMargin = Math.min(preMargin, vendorPricing[0]?.margin || Infinity);
        if (
          ['REJECTED', 'PRICING_APPROVED'].includes(workOrderForm?.values?.status) &&
          workOrderForm?.values?.status !== 'IN_REVIEW'
        ) {
          compareMargins(preMargin, currentMargin, showToast);
        }
        return filteredPrices;
      }
    }
    return [];
  };
  const updateDataToTable = (rowIndex, columnId, value) => {
    const tableData = [...workOrderForm.values.workOrderItems];
    let finalTableData = tableData.map((row, index) => {
      if (index === rowIndex) {
        switch (columnId) {
          case 'browseNodeId':
            const rowData = tableData[rowIndex];
            return {
              ...rowData,
              [columnId]: value,
              lineTotal: Number(rowData?.pricePerUnit) * Number(rowData?.qty),
              itemId: null,
              stateId: null,
              districtId: null,
            };
          case 'itemId':
            const rowValues = tableData[rowIndex];
            return {
              ...rowValues,
              [columnId]: value,
              stateId: isEmpty(value) ? null : rowValues.stateId,
              districtId: isEmpty(value) ? null : rowValues.districtId,
            };
          case 'sac':
          case 'pricePerUnit':
          case 'qty':
          case 'poLineItem':
          case 'stateId':
          case 'districtId':
          case 'creditType': {
            const updatedRow = {
              ...tableData[rowIndex],
              [columnId]: columnId === 'pricePerUnit' || columnId === 'qty' ? Number(value) : value,
            };

            updatedRow.lineTotal = roundOffAmount(
              Number(updatedRow.pricePerUnit) * Number(updatedRow.qty)
            );

            return updatedRow;
          }
          default:
            return {
              ...tableData[rowIndex],
              [columnId]: value,
            };
        }
      }
      return row;
    });
    workOrderForm.setFieldValue('workOrderItems', finalTableData);
    if (['browseNodeId', 'creditType', 'pricePerUnit', 'qty'].includes(columnId)) {
      if (!isProgress) {
        compareRealMargin(finalTableData);
      }
    }
    setMarginMeet(false);
    setUpdateTableData(true);
  };

  useEffect(() => {
    if (uploadedDocs?.length) {
      workOrderForm.setValues({ ...workOrderForm.values, documents: uploadedDocs });
      setUploadedDocs([]);
    }
  }, [uploadedDocs?.length]);

  const outerExtractedData = workOrderForm?.values?.workOrderItems?.map((item) => ({
    browseNodeId: item.browseNodeId,
    creditType: item.creditType,
    pricePerUnit: item.pricePerUnit,
    traceabilityDocument: workOrderForm?.values?.traceabilityDocuments,
  }));

  const isEBT = outerExtractedData.some((item) => ['3', '5', '7'].includes(item.browseNodeId));
  useEffect(() => {
    if (
      !isEBT &&
      !isEdit &&
      workOrderForm?.values?.workOrderItems?.every(
        (item) =>
          item.browseNodeId && item.creditType && item.pricePerUnit && !showPromptModal && showError
      )
    ) {
      validatePricingAndMargin(workOrderForm?.values?.workOrderItems);
    }
  }, [
    ...workOrderForm?.values?.workOrderItems?.map((item) => item.browseNodeId),
    ...workOrderForm?.values?.workOrderItems?.map((item) => item.creditType),
    ...workOrderForm?.values?.workOrderItems?.map((item) => item.pricePerUnit),
    ...workOrderForm?.values?.workOrderItems?.map((item) => item.qty),
    workOrderForm?.values?.traceabilityDocuments,
  ]);

  const documentAttach = async (documentReqData, isPODocument) => {
    try {
      const response = await WorkOrderService.uploadDocuments(documentReqData);

      if (response?.data) {
        const updatedDocs = isPODocument
          ? [...(workOrderForm.values.poDocuments || []), response?.data]
          : [...(workOrderForm.values.documents || []), response?.data];

        if (isPODocument) {
          workOrderForm.setFieldValue('poDocuments', updatedDocs);
        } else {
          workOrderForm.setFieldValue('documents', updatedDocs);
          setUploadedDocs(updatedDocs);
        }
      }
    } catch (error) {
      console.error('Document upload failed', error);
    }
  };

  const handleDocumentChange = (uploadDocList, isPODocument = false) => {
    uploadDocList.forEach((newData) => {
      newData.entityId = workOrderId;
      documentAttach(newData, isPODocument);
    });
    if (isPODocument) {
      workOrderForm.setFieldValue('poDocuments', [
        ...workOrderForm.values.poDocuments,
        ...uploadDocList,
      ]);
    } else {
      workOrderForm.setFieldValue('documents', [
        ...workOrderForm.values.documents,
        ...uploadDocList,
      ]);
    }
  };
  const deleteDocument = async (delDoc, isPoDocumnet) => {
    if (delDoc?.id) {
      await WorkOrderService.deleteWorkOrderDocument(delDoc.id);
    }
    let doc;
    if (isPoDocumnet) {
      doc = workOrderForm?.values?.poDocuments || [];
    } else {
      doc = workOrderForm?.values?.documents || [];
    }
    const updatedDocuments = doc.filter((e) => {
      if (delDoc?.id) {
        return e.id !== delDoc.id;
      } else if (delDoc?.viewUrl) {
        return e.viewUrl !== delDoc.viewUrl;
      } else if (delDoc?.fileName) {
        return e.fileName !== delDoc.fileName;
      }
    });

    if (isPoDocumnet) {
      workOrderForm.setFieldValue('poDocuments', updatedDocuments);
    } else {
      workOrderForm.setFieldValue('documents', updatedDocuments);
    }
  };

  const deleteRow = (rowIndex) => {
    const workOrderItems = [...workOrderForm.values.workOrderItems];
    const deletedItem = workOrderItems[rowIndex];
    workOrderItems.splice(rowIndex, 1);
    workOrderForm.setFieldValue('workOrderItems', [...workOrderItems]);
    deletedItem?.id &&
      setDeletedRows((prev) => [...prev, { ...deletedItem, isDeletedLineItem: true }]);
  };

  const addOrCopyRow = (rowIndex) => {
    let newRow = { ...NEW_ROW };
    const { workOrderFor, browseNodeId, itemId, estimatedDelivery, fulfillmentCycle } =
      workOrderForm?.values?.workOrderItems[rowIndex] || [];
    if (workOrderForm.values.toDate) {
      newRow.estimatedDelivery = workOrderForm.values.toDate;
    }
    workOrderForm.setFieldValue('workOrderItems', [
      ...workOrderForm.values.workOrderItems,
      {
        ...NEW_ROW,
        ...{
          workOrderFor: workOrderFor,
          browseNodeId: browseNodeId,
          itemId: itemId || null,
          estimatedDelivery: estimatedDelivery,
          fulfillmentCycle: fulfillmentCycle ?? getFulfillmentCycle(),
        },
      },
    ]);
  };

  const getFulfillmentCycle = () => {
    let cycle = '';
    if (!!workOrderForm.values.toCustomerId) {
      cycle =
        serviceProviders.find((e) => e.id === Number(workOrderForm.values.toCustomerId))
          ?.fulfillmentcycle || '';
    }
    return cycle;
  };

  const dropDownHandler = (a) => {
    workOrderForm.handleChange(a);
    if (a.target.name === 'traceabilityDocuments') {
      if (!isProgress) compareRealMargin(workOrderForm?.values?.workOrderItems);
    }
    setUpdateTableData(true);
  };

  const entityTypeHandler = (a) => {
    workOrderForm.handleChange(a);
    setCpcbWalletNumbers([]);
    workOrderForm.setFieldValue('cpcbWalletNumber', '');
    setMarginMeet(false);
  };

  const finYearHandler = (e) => {
    const DATES = e?.target?.value;
    setMarginMeet(false);
    const fulFilledYear = account?.financialYears?.find((item) => item?.label === DATES);
    workOrderForm.setValues({
      ...workOrderForm?.values,
      fulfillmentYearStartDate: fulFilledYear?.value?.fromDate,
      fulfillmentYearEndDate: fulFilledYear?.value?.toDate,
      fulfilledYear: fulFilledYear?.label,
    });
  };

  const isDataNotValid =
    rowsToHighlight.length ||
    !workOrderForm?.dirty ||
    updateSaveInProgress ||
    workOrderForm.values?.workOrderItems?.length < 1 ||
    !workOrderForm?.values.toDate ||
    !workOrderForm?.values.fromDate ||
    !workOrderForm?.values.fromCustomerId ||
    !checkSpecificValuesNotEmptyInArray(workOrderForm.values.workOrderItems, [
      'browseNodeId',
      'itemId',
    ]);

  const handlesaveEditedDetails = () => {
    setSaveEditedDetails((pre) => !pre);
    setSaveEditText('');
  };

  const submitData = () => {
    prepareWorkOrderData({
      ...workOrderForm.values,
      remarks: SaveEditText ? SaveEditText : '',
      draft: false,
      status: isProgress ? 'IN_PROGRESS' : hasPlastic ? 'IN_REVIEW' : 'IN_PROGRESS',
    });
  };

  const ModalsavedEditedDetails = () => {
    handlesaveEditedDetails();
    submitData();
  };

  const handleKeyDown = (event) => {
    const invalidCharecters = ['-', '+', 'e'];
    if (invalidCharecters.includes(event?.key)) {
      event.preventDefault();
    }
    setMarginMeet(false);
  };

  const hasPurchaseOrderDoc = workOrderForm?.values?.documents?.filter(
    (e) => e.type === 'WO_PURCHASE_ORDER'
  )?.length;

  const handleClientHandler = (e) => {
    workOrderForm.setValues({
      ...workOrderForm.values,
      fromCustomerId: e.id,
      cpcbWalletNumber: '',
      entityType: '',
    });
    setCpcbWalletNumbers([]);
    setMarginMeet(false);
  };

  const handleWalletNumber = (e) => {
    workOrderForm.setFieldValue('cpcbWalletNumber', e);
  };

  const isRejected = workOrderForm?.values?.status === 'REJECTED';
  const isDraft = workOrderForm?.values?.status === 'DRAFT';

  const isProgress = workOrderForm?.values?.status === 'IN_PROGRESS';

  const canSave =
    isBrand ||
    (isUserPRO &&
      hasPermission({
        permissionsAllowed: [
          {
            groupName: 'Execute_Targets',
            moduleName: 'Work_Orders',
            name: isEdit ? 'can_edit' : 'can_create',
          },
        ],
      }));

  const handleClose = () => setModalShow(false);
  const handlegoBack = () => {
    setGoBack((pre) => !pre);
  };

  const navigateBack = () => {
    setShowPromptModal(false);
  };

  const handleSaveAsDraft = () => {
    setShowError(false);
    workOrderForm.setFieldValue('draft', true);
    prepareWorkOrderData({
      ...workOrderForm?.values,
      draft: true,
    });
    setShowPromptModal(false);
    setShowPOModal(false);
  };

  const handleSubmitForPriceReview = () => {
    prepareWorkOrderData({
      ...workOrderForm.values,
      remarks: SaveEditText ? SaveEditText : '',
      draft: false,
      status: 'PRICING_REVIEW',
    });
  };
  const goToHistory = () => {
    isBrand ? history.push('/work-orders') : history.push('/work-orders-incoming');
  };

  const isPlanningDone = sum(workOrderForm?.values?.workOrderItems?.map((li) => li.plannedQty)) > 0;
  const isFulfillmentStarted = workOrderForm?.values?.workOrderItems?.some(
    (li) => li.fulfilledQty !== 0
  );
  const isFulfillmentDone = !workOrderForm?.values?.workOrderItems?.some(
    (li) => li.fulfilledQty !== li.qty
  );

  const getCpcbWalletInfo = async () => {
    try {
      const response = await WorkOrderService.fetchCpcbWalletInfo({
        customerId: workOrderForm.values?.fromCustomerId,
        type: ENTITY_TYPE_OPTIONS.find((type) => type.label === workOrderForm.values?.entityType)
          ?.enum,
      });
      response?.data?.length > 1
        ? setCpcbWalletNumbers(
            response?.data?.map((value) => ({
              id: value,
              value: value,
            }))
          )
        : workOrderForm.setFieldValue('cpcbWalletNumber', response?.data?.[0]);
    } catch (error) {
      console.log(error);
    }
  };

  const getVendorPricing = async () => {
    try {
      const response = await WorkOrderService.retrieveVendorPricing({
        moduleType: 'vendor',
        attributeType: 'pricing',
      });
      if ([200, 201].includes(response?.status)) {
        const newVendorPricing = response?.data;
        setVendorPricing(newVendorPricing);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getMaterialCategories = (ids) => {
    if (!ids) return [];
    const idArray = Array.isArray(ids) ? ids : [ids];
    return idArray.map((id) => {
      switch (id) {
        case '1':
          return 'CAT-1';
        case '2':
          return 'CAT-3';
        case '3':
          return 'E-Waste';
        case '4':
          return 'CAT-2';
        case '5':
          return 'Batteries';
        case '6':
          return 'CAT-4';
        case '7':
          return 'Tyres';
        default:
          return 'Unknown Category';
      }
    });
  };
  const validatePricingAndMargin = (data, showToast = true) => {
    if (!data) return;
    const extractedData = data?.map((item) => ({
      browseNodeId: item.browseNodeId,
      creditType: item.creditType,
      pricePerUnit: item.pricePerUnit,
      qty: item.qty,
    }));
    const traceabilityDocuments = workOrderForm?.values?.traceabilityDocuments ?? null;
    const browseNodeIds = extractedData.map((item) => item.browseNodeId);
    const categories = getMaterialCategories(browseNodeIds);
    const updatedData = extractedData.map((item, index) => ({
      ...item,
      categories: categories[index] || null,
      traceabilityDocument: traceabilityDocuments,
    }));
    const filteredPrices = getPriceValues(updatedData, vendorPricing);
    setPrices(filteredPrices);
    const margin = marginCalculator(filteredPrices, updatedData);
    let wholeWOMargin = wholeWOMarginCalculator(margin);
    setWholeWoMargin(wholeWOMargin);
    if (vendorPricing[0]?.margin <= wholeWOMargin) {
      if (
        workOrderForm.values?.status === 'DRAFT' ||
        (!isEdit && workOrderForm.values?.status !== 'IN_REVIEW') ||
        (showToast &&
          !['PRICING_APPROVED', 'REJECTED', 'IN_REVIEW'].includes(workOrderForm.values?.status))
      ) {
        toast.success('Margin Met Successfully');
      }
      setMarginMeet(false);
      setProfitMarginMet(true);
    } else {
      if (
        workOrderForm.values?.status === 'DRAFT' ||
        !isEdit ||
        (showToast &&
          !['PRICING_APPROVED', 'REJECTED', 'IN_PROGRESS'].includes(workOrderForm.values?.status))
      ) {
        toast.error('Margin does not Met.');
      }
      setMarginMeet(false);
      setProfitMarginMet(false);
    }
    return { wholeWOMargin, filteredPrices };
  };

  const getPriceValues = (updatedDataArray, vendorPricing) => {
    if (
      !vendorPricing ||
      vendorPricing.length === 0 ||
      !updatedDataArray ||
      updatedDataArray.length === 0
    ) {
      return [];
    }

    return updatedDataArray.map((updatedData) => {
      const matchingCategory = vendorPricing
        .flatMap((vp) => vp.catItems)
        .find((item) => item.category === updatedData.categories);
      if (!matchingCategory) return null;
      const pricingObject =
        String(updatedData.traceabilityDocument) === 'true'
          ? matchingCategory.processingType.withDoc
          : String(updatedData.traceabilityDocument) === 'false'
          ? matchingCategory.processingType.onlyCredit
          : null;
      return updatedData.creditType === 'RECYCLING'
        ? pricingObject?.Recycling ?? null
        : pricingObject?.EOL ?? null;
    });
  };
  const marginCalculator = (prices, updatedDataArray) => {
    if (!prices || prices.length === 0 || !updatedDataArray || updatedDataArray.length === 0) {
      return { totalProfit: 0, totalRevenue: 0, margin: 0 };
    }

    let totalProfit = 0;
    let totalRevenue = 0;

    updatedDataArray.forEach((item, index) => {
      const B = item.pricePerUnit;
      const I = prices[index];
      const quantity = item.qty;
      if (B && I && quantity) {
        totalProfit += (B - I) * quantity;
        totalRevenue += B * quantity;
      }
    });
    const margin = totalRevenue !== 0 ? ((totalProfit / totalRevenue) * 100).toFixed(2) : 0;

    return [parseFloat(margin)];
  };

  const wholeWOMarginCalculator = (margin) => {
    let wholeWOMargin = 0;
    if (Array.isArray(margin) && margin.length > 0) {
      for (let i = 0; i < margin.length; i++) {
        if (!isNaN(margin[i])) {
          wholeWOMargin += margin[i] / margin?.length;
        } else {
          console.warn(`Skipping invalid margin value at index ${i}:`, margin[i]);
        }
      }
    } else {
      console.warn('Invalid or empty margin array');
    }
    return wholeWOMargin;
  };
  const isPOModaluploaded = workOrderForm?.values?.poDocuments?.some(
    (doc) => doc?.type === 'WO_PURCHASE_ORDER'
  );
  const isPOuploaded = workOrderForm?.values?.documents?.some(
    (doc) => doc?.type === 'WO_PURCHASE_ORDER'
  );
  const handlePOModalClose = () => {
    workOrderForm.setValues({
      ...workOrderForm.values,
      poNumber: '',
      poDate: '',
    });
    setShowPOModal(false);
  };
  const onClose = () => setShowModal(false);

  const fetchBrandPrices = async () => {
    try {
      const response = await MarginApproval.getBrandPricing();
      if (response?.status === 200) {
        setBrandPricingList(response?.data?.[0]);
      }
    } catch (error) {
      toast.error('Something went wrong!');
    }
  };

  const isDisabled = workOrderForm.values?.status === 'PRICING_APPROVED' && isEdit === 'true';
  const showSubmitCreateButton = () => {
    if (isEBT) {
      return true;
    }

    if (isEdit) {
      if (workOrderForm?.values?.status === 'IN_PROGRESS') {
        return true;
      }

      if (workOrderForm?.values?.status === 'PRICING_APPROVED') {
        if (
          hasPurchaseOrderDoc &&
          workOrderForm?.values?.poDate &&
          workOrderForm?.values?.poNumber
        ) {
          return initialDisplay || isAllowedForReview;
        }
      }

      if (
        workOrderForm?.values?.status === 'REJECTED' &&
        hasPurchaseOrderDoc &&
        workOrderForm?.values?.poDate &&
        workOrderForm?.values?.poNumber
      ) {
        return true;
      }

      if (isDraft) {
        return false;
      }
    } else {
      if (hasPlastic && hasPurchaseOrderDoc && profitMarginMet) {
        return true;
      }
    }

    return false;
  };
  const disableUploadPoAndPriceReview = () => {
    return (
      isLoading ||
      workOrderForm?.values?.workOrderItems?.some(
        (item) =>
          !item.browseNodeId ||
          !item.itemId ||
          !item.creditType ||
          item.qty === null ||
          item.qty === undefined ||
          item.qty <= 0 ||
          item.pricePerUnit === null ||
          item.pricePerUnit === undefined ||
          item.pricePerUnit <= 0
      )
    );
  };

  const disableFields = () => {
    if (isEBT) {
      return false;
    } else if (hasPlastic && isRejected) {
      return false;
    } else if (hasPlastic && !isEdit) {
      return true;
    } else if (workOrderForm.values?.status === 'PRICING_APPROVED' && hasPlastic) {
      return false;
    }
    return true;
  };
  function previousMarginalculator(workOrderItems) {
    let totalProfit = 0;
    let totalRevenue = 0;

    if (!workOrderItems || !Array.isArray(workOrderItems)) {
      return 0;
    }

    workOrderItems.forEach((item) => {
      const pricePerUnit = parseFloat(item.pricePerUnit) || 0;
      const idealPrice = parseFloat(item.idealPrice) || 0;
      const qty = parseInt(item.qty) || 0;
      totalProfit += (pricePerUnit - idealPrice) * qty;
      totalRevenue += pricePerUnit * qty;
    });
    const margin = totalRevenue !== 0 ? (totalProfit / totalRevenue) * 100 : 0;
    setPrevMargin(margin?.toFixed(2));
    return margin.toFixed(2);
  }

  const singleButton =
    workOrderForm?.values?.status === 'PRICING_APPROVED' ||
    workOrderForm?.values?.status === 'REJECTED';

  const handleSubmitForReviewClick = () => {
    if (isEBT && !isDraft && !isProgress) {
      submitData();
    } else if (profitMarginMet) {
      setShowPOModal((prev) => !prev);
      setShowPromptModal(false);
      setSubmitForInreviewClicked(true);
    } else if (currentMarginUnmet && isRejected && hasPlastic) {
      setShowPromptModal(true);
      setShowPOModal(false);
    } else {
      isProgress && isEdit ? handlesaveEditedDetails() : submitData();
    }
  };

  const disableCreateWorkOrderButton = () => {
    const isFormInvalid =
      !workOrderForm?.values?.workOrderItems?.length ||
      rowsToHighlight.length ||
      updateSaveInProgress ||
      !isEmpty(workOrderForm?.errors) ||
      workOrderForm?.values?.isSaveButtonDisabled ||
      workOrderForm.errors?.isInvoicing;

    if (isEBT) {
      return (
        !hasPurchaseOrderDoc ||
        (!isDraft && !workOrderForm?.dirty && !isRejected) ||
        isFormInvalid ||
        !isEBT
      );
    }
    return isFormInvalid || (workOrderForm.values.status === 'DRAFT' && !profitMarginMet);
  };

  const handleSaveAsDraftClick = () => {
    if (isEBT) {
      workOrderForm.setFieldValue('draft', true);
      prepareWorkOrderData({ ...workOrderForm?.values, draft: true });
    } else if (!profitMarginMet) {
      setShowPromptModal(true);
      setShowPOModal(false);
    } else {
      setShowPOModal(true);
      setShowPromptModal(false);
      setSubmitForInreviewClicked(false);
    }
  };

  return (
    <div className={'animated fadeIn'}>
      <React.Suspense fallback={<Loader className="page-loading" />}>
        {isLoading ? (
          <Loader className="page-loading" />
        ) : (
          <Card className="main-card ">
            <Form id="createWorkOrderForm" onSubmit={(e) => workOrderForm.handleSubmit(e)}>
              <Card.Header className="bg-white border-0  py-0">
                <Row className="padding-aligned align-self-center">
                  <Col className="align-self-center" xs={'3'}>
                    <div className={'d-flex align-items-center'}>
                      <Button
                        className={'mr-2'}
                        onClick={workOrderForm?.dirty ? handlegoBack : goToHistory}>
                        <FontAwesomeIcon icon={faChevronLeft} />
                      </Button>
                      {workOrderForm?.values?.id ? (
                        <span className=" wotitleflex">
                          <h4> {workOrderForm?.values?.id} </h4>
                          <Badge
                            style={{
                              backgroundColor:
                                WORK_ORDER_DISPLAY_STATUS?.[workOrderForm?.values?.status]?.bg,
                              color:
                                WORK_ORDER_DISPLAY_STATUS?.[workOrderForm?.values?.status]
                                  ?.textColor,
                              border: `1px solid ${
                                WORK_ORDER_DISPLAY_STATUS?.[workOrderForm?.values?.status]
                                  ?.borderColor
                              }`,
                              borderRadius: '16px',
                              padding: '6px',
                              fontSize: '12px',
                              fontWeight: 500,
                              textAlign: 'center',
                              fontFamily: 'Inter',
                            }}>
                            {
                              WORK_ORDER_DISPLAY_STATUS?.[workOrderForm?.values?.status]
                                ?.displayText
                            }
                          </Badge>
                        </span>
                      ) : (
                        <h4 className="">Create Work Order</h4>
                      )}
                    </div>
                  </Col>
                  <Col className="pl-0 text-right justify-content-end">
                    {isRejected ||
                      (workOrderForm?.values.status === 'PRICING_APPROVED' && (
                        <Button
                          className={'save_button_brand mr-3'}
                          variant={'outline'}
                          disabled={!workOrderForm?.dirty}
                          onClick={() => {
                            prepareWorkOrderData({
                              ...workOrderForm.values,
                              remarks: SaveEditText ? SaveEditText : '',
                              draft: false,
                              status:
                                workOrderForm?.values.status === 'PRICING_APPROVED'
                                  ? 'PRICING_APPROVED'
                                  : 'REJECTED',
                            });
                          }}>
                          Save
                        </Button>
                      ))}
                    <Button
                      className={'discard_button_brand mr-3'}
                      variant={'outline'}
                      onClick={workOrderForm?.dirty ? handlegoBack : goToHistory}>
                      Discard
                    </Button>
                    {hasPermission({
                      permissionsAllowed: [
                        {
                          groupName: 'Margin_Approval',
                          moduleName: 'Brand_Pricing',
                          name: 'can_view',
                        },
                      ],
                    }) && (
                      <>
                        {PLASTIC_BROWSE_NODE_IDS?.includes(
                          workOrderForm.values?.workOrderItems?.[0]?.browseNodeId
                        ) && (
                          <Button
                            variant="outline-primary"
                            className={'discard_button_brand mr-3'}
                            onClick={() => setShowModal(true)}>
                            Ideal Brand Price
                          </Button>
                        )}
                      </>
                    )}

                    {((!isProgress &&
                      isEdit &&
                      !isRejected &&
                      workOrderForm?.values?.status === 'PRICING_APPROVED' &&
                      isMarginCompared) ||
                      isDraft ||
                      (!isEdit &&
                        !['IN_PROGRESS', 'IN_REVIEW', 'CLOSED'].includes(
                          workOrderForm?.values?.status
                        ))) && (
                      <Button
                        className={'discard_button_brand mr-3'}
                        style={{
                          backgroundColor:
                            workOrderForm?.values?.status === 'PRICING_APPROVED' ? '#005DFF' : '',
                          color:
                            workOrderForm?.values?.status === 'PRICING_APPROVED' ? 'white' : '',
                        }}
                        variant={'outline'}
                        disabled={
                          (workOrderForm?.values?.status !== 'PRICING_APPROVED' &&
                            isDataNotValid) ||
                          (!isEBT && marginMeet)
                        }
                        onClick={handleSaveAsDraftClick}>
                        {workOrderForm?.values?.status === 'PRICING_APPROVED'
                          ? 'Submit for Review'
                          : 'Save as Draft'}
                      </Button>
                    )}

                    {canSave && showSubmitCreateButton() && (
                      <Button
                        className="save_button_brand"
                        disabled={disableCreateWorkOrderButton()}
                        onClick={() => handleSubmitForReviewClick()}>
                        {isProgress
                          ? 'Update Work Order'
                          : hasPlastic
                          ? 'Submit for Review'
                          : 'Create Work Order'}
                      </Button>
                    )}
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body className={'p-0'}>
                {canSave ? (
                  <Row>
                    <Col xs={12}>
                      <div style={{ padding: '2rem 2rem 0rem 2rem' }}>
                        <Row>
                          <Col md={6}>
                            <h5 className="mb-1">Provide Work Order Details</h5>
                          </Col>
                          <Col md={6} className="text-right mb-3">
                            <Button
                              variant="outline"
                              onClick={() => setModalShow(true)}
                              className="comments_btn_brand"
                              style={{ border: '1px solid #D0D5DD' }}>
                              {workOrderId && workOrderForm.values?.extraComments
                                ? 'Edit Comments'
                                : ' Add Comments'}
                            </Button>
                          </Col>
                        </Row>
                        {workOrderForm?.values?.fromCustomerId &&
                          workOrderForm?.values?.entityType &&
                          !isValidEntity(workOrderForm?.values?.entityType) && (
                            <div className="invalid-entity-div">
                              {workOrderForm?.values?.entityType} CPCB details are not available.
                              Kindly update the information.
                              <div
                                onClick={() => {
                                  const fromCustomerId = workOrderForm?.values?.fromCustomerId;
                                  if (
                                    !isDataNotValid &&
                                    ((!isProgress && isEdit && !isRejected) ||
                                      isDraft ||
                                      (!isEdit &&
                                        !['IN_PROGRESS', 'IN_REVIEW', 'CLOSED'].includes(
                                          workOrderForm?.values?.status
                                        )))
                                  ) {
                                    workOrderForm.setFieldValue('draft', true);
                                    prepareWorkOrderData(
                                      { ...workOrderForm?.values, draft: true },
                                      false
                                    );
                                  }
                                  history.push(`/client/${fromCustomerId}?editCPCB=true`);
                                }}>
                                Click here
                                <ArrowUpRight />
                              </div>
                            </div>
                          )}
                        <Row className={'details-filter'}>
                          <Col xs={12} sm={12} md={12} lg={8.5} xl={8} className={'pt-2'}>
                            <Row>
                              {isUserPRO ? (
                                <Col xs={3}>
                                  <Form.Group>
                                    <Form.Label className="label_wodetails">
                                      Brand <span className="mandatory-astrik-color">*</span>
                                    </Form.Label>
                                    <DropdownSelect
                                      className="form-control-one"
                                      name="fromCustomerId"
                                      id="fromCustomerId"
                                      onChange={(e) => handleClientHandler(e)}
                                      isDisabled={isProgress || isDisabled}
                                      value={workOrderForm?.values?.fromCustomerId}
                                      options={clients}
                                      getOptionLabel={(option) => `${option.name}`}
                                      getOptionValue={(option) => `${option.id}`}
                                    />
                                  </Form.Group>
                                </Col>
                              ) : null}
                              <Col xs={3}>
                                <Form.Group>
                                  <Form.Label className="label_wodetails">
                                    Entity Type <span className="mandatory-astrik-color">*</span>
                                  </Form.Label>
                                  <Form.Control
                                    as="select"
                                    className="px-3 form-control-one"
                                    name="entityType"
                                    id="entityType"
                                    loading={loadingBrandDetails}
                                    disabled={
                                      (!!workOrderForm?.initialValues?.entityType &&
                                        isProgress &&
                                        isFulfillmentStarted &&
                                        isPlanningDone) ||
                                      loadingBrandDetails
                                    }
                                    onChange={entityTypeHandler}
                                    isInvalid={!!workOrderForm.errors.entityType}
                                    value={workOrderForm?.values?.entityType}>
                                    <option key={'ENTITY_TYPE_NONE'} value="">
                                      Select option
                                    </option>
                                    {ENTITY_TYPE_OPTIONS.map((type) => {
                                      return (
                                        <option key={type?.id} value={type?.label}>
                                          {type?.label}
                                        </option>
                                      );
                                    })}
                                  </Form.Control>
                                </Form.Group>
                              </Col>
                              <Col xs={3}>
                                <Form.Group>
                                  <Form.Label className="label_wodetails">
                                    CPCB Wallet Number{' '}
                                    <span className="mandatory-astrik-color">*</span>
                                  </Form.Label>
                                  {!isEmpty(cpcbWalletNumbers) ? (
                                    <DropdownSelect
                                      className="form-control-one"
                                      name="cpcbWalletNumber"
                                      id="cpcbWalletNumber"
                                      onChange={(e) => handleWalletNumber(e?.id)}
                                      value={workOrderForm?.values?.cpcbWalletNumber}
                                      options={cpcbWalletNumbers}
                                      getOptionLabel={(option) => `${option.value}`}
                                      getOptionValue={(option) => `${option.id}`}
                                      isInvalid={!!workOrderForm.errors.cpcbWalletNumber}
                                    />
                                  ) : (
                                    <Form.Control
                                      name="cpcbWalletNumber"
                                      id="cpcbWalletNumber"
                                      disabled={true}
                                      isInvalid={!!workOrderForm.errors.cpcbWalletNumber}
                                      {...workOrderForm.getFieldProps('cpcbWalletNumber')}
                                      value={
                                        workOrderForm?.values?.cpcbWalletNumber
                                      }></Form.Control>
                                  )}{' '}
                                </Form.Group>
                              </Col>
                              <Col xs={3}>
                                <Form.Group>
                                  <Form.Label className="label_wodetails">
                                    Traceability Documents{' '}
                                    <span className="mandatory-astrik-color">*</span>
                                  </Form.Label>
                                  <Form.Control
                                    as="select"
                                    className="px-3 form-control-one"
                                    name="traceabilityDocuments"
                                    id="traceabilityDocuments"
                                    onChange={dropDownHandler}
                                    disabled={isProgress && isPlanningDone}
                                    isInvalid={!!workOrderForm.errors.traceabilityDocuments}
                                    value={workOrderForm?.values?.traceabilityDocuments}>
                                    <option key={'TRACEABILITY_DOCS_NONE'} value="">
                                      Select option
                                    </option>
                                    {TRACEABILITY_DOCS_OPTIONS.map((type) => {
                                      return (
                                        <option key={type?.id} value={type?.value}>
                                          {type?.label}
                                        </option>
                                      );
                                    })}
                                  </Form.Control>
                                </Form.Group>
                              </Col>
                              <Col xs={3}>
                                <Form.Group
                                  className="date-picker-bg-img-icon left-picker"
                                  id="startDate">
                                  <Form.Label className="label_wodetails">
                                    Start Date <span className="mandatory-astrik-color">*</span>
                                  </Form.Label>
                                  <RcylDatePicker
                                    showDropdowns={true}
                                    singleDatePicker
                                    disabled={isProgress && isPlanningDone}
                                    startDate={workOrderForm.values.fromDate}
                                    onApply={({ startDate }) => {
                                      setMarginMeet(false);
                                      !deliveryExists &&
                                        workOrderForm.setValues({
                                          ...workOrderForm?.values,
                                          fromDate: startDate,
                                          toDate: null,
                                        });
                                    }}
                                    isInvalid={!!workOrderForm.errors.fromDate}
                                    errorMessage={workOrderForm.errors.fromDate}
                                  />
                                </Form.Group>
                              </Col>
                              <Col xs={3}>
                                <Form.Group
                                  className="date-picker-bg-img-icon left-picker "
                                  id="toDate">
                                  <Form.Label className="label_wodetails">
                                    Estimated delivery date{' '}
                                    <span className="mandatory-astrik-color">*</span>
                                  </Form.Label>
                                  <RcylDatePicker
                                    minDate={workOrderForm.values.fromDate}
                                    singleDatePicker
                                    showDropdowns={true}
                                    disabled={isProgress && isPlanningDone}
                                    startDate={workOrderForm.values.toDate ?? ''}
                                    onApply={({ startDate, endDate }, $event) => {
                                      !deliveryExists &&
                                        workOrderForm.setFieldValue('toDate', startDate);
                                      setMarginMeet(false);
                                    }}
                                    isInvalid={!!workOrderForm.errors.toDate}
                                    errorMessage={workOrderForm.errors.toDate}
                                  />
                                </Form.Group>
                              </Col>

                              <Col xs={3}>
                                <Form.Group
                                  className="date-picker-bg-img-icon left-picker "
                                  id="deliveryDate">
                                  <Form.Label className="label_wodetails">
                                    PO Date <span className="mandatory-astrik-color">*</span>
                                  </Form.Label>
                                  <RcylDatePicker
                                    disabled={
                                      (isProgress && !!workOrderForm?.initialValues?.poDate) ||
                                      disableFields()
                                    }
                                    minDate={poDate}
                                    singleDatePicker
                                    startDate={workOrderForm.values.poDate}
                                    onApply={({ startDate, endDate }, $event) => {
                                      workOrderForm.setFieldValue('poDate', startDate);
                                    }}
                                    showDropdowns={true}
                                    isInvalid={!!workOrderForm.errors.poDate}
                                    errorMessage={workOrderForm.errors.poDate}
                                  />
                                </Form.Group>
                              </Col>
                              <Col xs={3}>
                                <Form.Group>
                                  <Form.Label className="label_wodetails">
                                    PO Number <span className="mandatory-astrik-color">*</span>
                                  </Form.Label>
                                  <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 100, hide: 400 }}
                                    overlay={
                                      <Tooltip id="button-tooltip">
                                        This is external Reference ID
                                      </Tooltip>
                                    }>
                                    <FontAwesomeIcon
                                      className="text-muted ml-1"
                                      icon={faQuestionCircle}
                                    />
                                  </OverlayTrigger>
                                  <Form.Control
                                    name="poNumber"
                                    id="poNumber"
                                    disabled={
                                      (isProgress && !!workOrderForm.initialValues?.poNumber) ||
                                      disableFields()
                                    }
                                    isInvalid={!!workOrderForm.errors.poNumber}
                                    {...workOrderForm.getFieldProps('poNumber')}
                                    value={
                                      workOrderForm?.values?.poDetails &&
                                      workOrderForm?.values?.poDetails.length
                                        ? workOrderForm?.values?.poDetails
                                            ?.map((poDetail) => {
                                              return poDetail.purchaseOrderNumber;
                                            })
                                            .join(', ')
                                        : workOrderForm?.values?.poNumber || ''
                                    }></Form.Control>
                                </Form.Group>
                                <p className="error-style">{workOrderForm.errors.poNumber}</p>
                              </Col>
                              <Col xs={3}>
                                <Form.Group>
                                  <Form.Label className="label_wodetails">
                                    Payment Terms(Days){' '}
                                    <span className="mandatory-astrik-color">*</span>
                                  </Form.Label>
                                  <Form.Control
                                    name="paymentTerm"
                                    id="paymentTerm"
                                    type="number"
                                    onKeyDown={handleKeyDown}
                                    isInvalid={!!workOrderForm.errors.paymentTerm}
                                    {...workOrderForm.getFieldProps('paymentTerm')}
                                    disabled={isProgress}></Form.Control>
                                </Form.Group>
                                {workOrderForm.errors.paymentTerm && (
                                  <p className="error-style">Payment Term required.</p>
                                )}
                              </Col>

                              <Col xs={3}>
                                <Form.Group>
                                  <Form.Label className="label_wodetails">
                                    Fulfillment Year{' '}
                                    <span className="mandatory-astrik-color">*</span>
                                  </Form.Label>
                                  <Form.Control
                                    as="select"
                                    className="px-3 form-control-one"
                                    name="fulfilledYear"
                                    id="fulfilledYear"
                                    onChange={finYearHandler}
                                    isInvalid={
                                      workOrderForm?.errors?.fulfillmentYearStartDate &&
                                      (!!workOrderForm.errors.fulfillmentYearStartDate ||
                                        !!workOrderForm.errors.fulfillmentYearEndDate ||
                                        !workOrderForm.values.fulfilledYear)
                                    }
                                    value={workOrderForm.values.fulfilledYear}
                                    disabled={isProgress && isPlanningDone}>
                                    <option key={'FIN_YEAR_NONE'} value="">
                                      Select option
                                    </option>
                                    {account?.financialYears?.map((ele) => (
                                      <option key={ele.label} value={ele.label}>
                                        {ele.label}
                                      </option>
                                    ))}
                                  </Form.Control>
                                  <Form.Control.Feedback type="invalid">
                                    {workOrderForm.errors.fulfilledYear}
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Col>
                              <Col xs={3}>
                                <Form.Group>
                                  <Form.Label className="label_wodetails">
                                    Fulfillment Cycle
                                  </Form.Label>
                                  <Form.Control
                                    as="select"
                                    className="px-3 form-control-one"
                                    name="fulfillmentCycle"
                                    id="fulfillmentCycle"
                                    onChange={(e) => {
                                      workOrderForm.setFieldValue(
                                        'fulfillmentCycle',
                                        e.target.value
                                      );
                                      setMarginMeet(false);
                                    }}
                                    isInvalid={
                                      !!workOrderForm.errors.fulfillmentCycle ||
                                      !!workOrderForm.errors.fulfillmentCycle ||
                                      !!workOrderForm.fulfillmentCycle
                                    }
                                    value={workOrderForm.values.fulfillmentCycle}
                                    disabled={isProgress && isPlanningDone}>
                                    <option key={'FULFILLMENTCYCLE'} value="">
                                      Select option
                                    </option>
                                    {FULFILLMENT_CYCLES?.map((ele) => {
                                      return (
                                        <option key={ele?.label} value={ele?.value}>
                                          {ele?.label}
                                        </option>
                                      );
                                    })}
                                  </Form.Control>
                                  <Form.Control.Feedback type="invalid">
                                    {workOrderForm.errors.fulfillmentCycle}
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Col>
                              <Col xs={5}>
                                <Form.Group>
                                  <Form.Label
                                    className="label_wodetails"
                                    style={{ display: 'block' }}>
                                    Additional Invoicing requirements{' '}
                                    <span className="mandatory-astrik-color">*</span>
                                  </Form.Label>
                                  {[
                                    { id: '1', label: 'Yes', value: true },
                                    { id: '2', label: 'No', value: false },
                                  ].map((item, id) => (
                                    <Form.Check
                                      key={`${id}`}
                                      inline
                                      type="radio"
                                      name={`isInvoicing-${id}`}
                                      id={`inline-radio-${id + 1}`}
                                      label={item?.label}
                                      className="invoicing-radio mt-3"
                                      onChange={() => {
                                        workOrderForm.setValues({
                                          ...workOrderForm?.values,
                                          isInvoicing: item.value,
                                          invoicing: item.value,
                                          comments: null,
                                        });
                                        if (item?.value) {
                                          setCommentValidation({
                                            comments: Yup.string('Comments required')
                                              .typeError('Comments are required')
                                              .required('Comments are required'),
                                          });
                                        }
                                        if (!item?.value) {
                                          setCommentValidation({});
                                        }
                                      }}
                                      checked={workOrderForm.values?.isInvoicing === item.value}
                                      disabled={
                                        !isNull(workOrderForm.initialValues?.isInvoicing) &&
                                        isProgress &&
                                        isFulfillmentStarted
                                      }
                                    />
                                  ))}
                                  {workOrderForm.errors?.isInvoicing && (
                                    <div
                                      className="invalid-feedback-form-check"
                                      style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>
                                      {workOrderForm.errors?.isInvoicing}
                                    </div>
                                  )}
                                </Form.Group>
                              </Col>
                              {workOrderForm?.values?.isInvoicing && (
                                <Col xs={6}>
                                  <Form.Group>
                                    <Form.Label className="label_wodetails">
                                      Invoicing Comments{' '}
                                      <span className="mandatory-astrik-color">*</span>
                                    </Form.Label>
                                    <Form.Control
                                      name="comments"
                                      id="comments"
                                      type="comments"
                                      maxLength={600}
                                      isInvalid={!!workOrderForm.errors.comments}
                                      {...workOrderForm.getFieldProps('comments')}
                                      disabled={isProgress && isFulfillmentDone}></Form.Control>
                                  </Form.Group>
                                  {workOrderForm.errors.comments && (
                                    <p className="error-style">Comments Required.</p>
                                  )}
                                </Col>
                              )}
                            </Row>
                          </Col>
                          <Col xs={12} md={12} sm={12} lg={3.5} xl={4}>
                            <MultiUploadCard
                              documents={workOrderForm.values?.documents || []}
                              handleDocChange={handleDocumentChange}
                              deletDoc={deleteDocument}
                              canUpdate={true}
                              isEdit={true}
                              isProgress={isProgress}
                              isFulfillmentStarted={isFulfillmentStarted}
                              initialDocuments={workOrderForm?.initialValues?.documents || []}
                              status={workOrderForm?.values?.status}
                            />
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col xs={12}>
                      <Row style={{ padding: '0rem 2rem' }} className={' details-filter'}>
                        <Col xs={12}>
                          <h6 className="mb-0" style={{ fontFamily: 'Inter' }}>
                            Target Allocation
                          </h6>
                        </Col>
                        <Col xs={12} className={'pt-3'}>
                          <BrandWorkOrderItemsEdit
                            isEdit={isEdit}
                            isNotMandatory={workOrderForm?.values?.draft}
                            basicDetails={workOrderForm.values}
                            updateHandler={updateDataToTable}
                            addNewRowHandler={addOrCopyRow}
                            deleteRowHandler={deleteRow}
                            copyRowHandler={addOrCopyRow}
                            deliveryExists={deliveryExists}
                            rowsToHighlight={rowsToHighlight ? rowsToHighlight : []}
                            browseNodes={browseNodes}
                            regions={regions}
                            addRow={true}
                            orderSummary={orderSummary}
                            isHUL={
                              account?.user?.profile?.id === 9053 ||
                              workOrderForm?.values?.fromCustomerId === 9053
                            }
                            isUserPRO={isUserPRO}
                            handleKeyDown={handleKeyDown}
                            isBRANDWO={true}
                            maxValueByCategory={maxValueByCategory}
                            workOrderForm={workOrderForm}
                            setDeletedRows={setDeletedRows}
                            deletedRows={deletedRows}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                ) : (
                  <AccessDenied />
                )}
              </Card.Body>
            </Form>
          </Card>
        )}
      </React.Suspense>
      {showModal && (
        <BrandPricingModal showModal={showModal} onClose={onClose} pricingList={brandPricingList} />
      )}
      <CommentsModal
        modalShow={modalShow}
        handleClose={handleClose}
        workOrderForm={workOrderForm}
        mode={workOrderId ? 'edit' : 'add'}
        workOrder={originalWorkOrderData}
      />
      {goBack && (
        <PromptModal
          singleButton={false}
          show={goBack}
          title="Unsaved Changes"
          text="Are you sure you want to discard unsaved changes?"
          onDismiss={handlegoBack}
          onSuccess={goToHistory}
          onHide={handlegoBack}
          dismissBtnText="Cancel"
          successBtnText="Continue"
        />
      )}
      {saveEditedDetails && (
        <PromptInputModal
          open={saveEditedDetails}
          title="Updating Order?"
          heading="Specify Reason"
          placeholder="Write the reason here"
          buttonText="Save Changes"
          onHide={handlesaveEditedDetails}
          data={SaveEditText}
          setData={setSaveEditText}
          onSubmit={ModalsavedEditedDetails}
        />
      )}

      {showPromptModal && (
        <PromptModal
          singleButton={false}
          show={showPromptModal}
          title="Low margin risk"
          text="The entered prices are below the minimum pricing requirements. This Work Order requires special pricing approval."
          onDismiss={handleSaveAsDraft}
          onSuccess={handleSubmitForPriceReview}
          onHide={navigateBack}
          dismissBtnText="Save as Draft"
          successBtnText="Submit for Price review"
          isDisabled={disableUploadPoAndPriceReview()}
          hideDismissBtn={singleButton}
        />
      )}

      {profitMarginMet && showPOModal && (
        <POModal
          show={showPOModal}
          setShowPOModal={setShowPOModal}
          handleClose={handlePOModalClose}
          workOrderForm={workOrderForm}
          isPOModaluploaded={isPOModaluploaded}
          isPOuploaded={isPOuploaded}
          handleDocumentChange={handleDocumentChange}
          deleteDocument={deleteDocument}
          isProgress={isProgress}
          handleSaveAsDraft={handleSaveAsDraft}
          initialDocuments={workOrderForm?.initialValues?.documents || []}
          poDocuments={workOrderForm.values?.poDocuments || []}
          submitForInreviewClicked={submitForInreviewClicked}
          isEdit={isEdit}
          handlesaveEditedDetails={handlesaveEditedDetails}
          submitData={submitData}
          disableUploadPoAndPriceReview={disableUploadPoAndPriceReview()}
          isLoading={isLoading}
        />
      )}
    </div>
  );
};

export default WorkOrderCreateBrandPRO;

import API from '../../shared/Config/Api';
import APIEndpoints from '../../shared/Config/ApiEndpoints.const';

export const fetchBrandFulfillmentTracking = async (params) => {
  return await API.get(APIEndpoints.BRAND_FULFILLMENT_TRACKING, null, params);
};

export const fetchVendorFulfillmentTracking = async (params) => {
  return await API.get(APIEndpoints.VENDOR_FULFILLMENT_TRACKING, null, params);
};

export const fetchWorkOrderById = async (workOrderId, params) => {
  return await API.get(APIEndpoints.WORK_ORDERS + '/' + workOrderId, null, params);
};

export const fetchCategoryWiseTableDataForBrand = async (workOrderId) => {
  return await API.get(APIEndpoints.BRAND_CATEGORYWISE_FULFILLMENT_DATA + '/' + workOrderId);
};

export const fetchVendorWorkOrders = async (vendorId, params) => {
  return await API.get(APIEndpoints.VENDOR_WORK_ORDERS + '/' + vendorId, null, params);
};

export const fetchVendorDetails = async (vendorId) => {
  return await API.get(APIEndpoints.VENDOR_DETAILS + '/' + vendorId);
};

export const fetchCategoryWiseTableDataForVendor = async (vendorId, params) => {
  return await API.get(
    APIEndpoints.VENDOR_CATEGORYWISE_FULFILLMENT_DATA + '/' + vendorId,
    null,
    params
  );
};

export const fetchVendorWorkOrderOverviewDetails = async (params) => {
  return await API.get(APIEndpoints.FETCH_VENDOR_WORKORDER_OVERVIEW_DETAILS, null, params);
};

export const fetchDeliveryExecutionDetails = async (params) => {
  return await API.get(APIEndpoints.FETCH_DELIVERY_EXECUTION_DETAILS, null, params);
};

export const fetchAuditLogs = async (params) => {
  return await API.get(APIEndpoints.GET_AUDIT_LOGS, null, params);
};

export const getExecutionData = async (params) => {
  return await API.get(APIEndpoints.EXECUTION, null, params);
};

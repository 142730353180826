import API from '../../shared/Config/Api';
import APIEndpoints from '../../shared/Config/ApiEndpoints.const';

const fetchServiceProviders = async () => {
  return await API.get(APIEndpoints.SERVICE_PROVIDER, null, {
    size: 1000,
    vendorTypes: 'SERVICE_PROVIDER, PWP',
  });
};

const fetchSps = async () => {
  return await API.get(APIEndpoints.SERVICE_PROVIDER, null, {
    size: 1000,
    vendorTypes: 'SERVICE_PROVIDER',
  });
};

const fetchPwp = async () => {
  return await API.get(APIEndpoints.SERVICE_PROVIDER, null, {
    size: 1000,
    vendorTypes: 'PWP',
  });
};

const getPaymentTerm = async (customerId) => {
  return await API.get(APIEndpoints.GET_PAYMENT_TERM, null, {
    customerId,
  });
};

export const serviceProviderAPI = {
  fetchServiceProviders,
  fetchSps,
  fetchPwp,
  getPaymentTerm,
};

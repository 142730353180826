import { useFormik } from 'formik';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useMemo } from 'react';
import { Button, Col, Modal, Row, Table } from 'react-bootstrap';
import { useTable } from 'react-table';
import * as Yup from 'yup';
import droppingIcon from '../../assets/img/dropping.svg';
import risingIcon from '../../assets/img/rising.svg';
import stableIcon from '../../assets/img/stable.svg';
import { Loader } from '../../shared/Components';
import { PRICING_TRENDS, pricing_trend } from '../../shared/Constants/Constants';
import { toCapitalize } from '../../shared/Utils/Helper';

function BrandPricingModal({ showModal, onClose, pricingList, isLoading }) {
  const formik = useFormik({
    initialValues: {
      catItems: !isEmpty(pricingList)
        ? pricingList.catItems.map((item) => ({
            category: item?.category || '',
            onlyCreditEOL: item?.processingType?.onlyCredit?.EOL || 0,
            withDocEOL: item?.processingType?.withDoc?.EOL || 0,
            onlyCreditRecycling: item?.processingType?.onlyCredit?.Recycling,
            withDocRecycling: item?.processingType?.withDoc?.Recycling,
            validTill: item?.validTill ? moment(item?.validTill).format('DD-MM-YYYY') : '',
            trend: item?.trend,
          }))
        : [],
    },
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema: Yup.object().shape({
      catItems: Yup.array().of(
        Yup.object().shape({
          category: Yup.string().required('Category is required'),
          onlyCreditEOL: Yup.number().required('This field is required'),
          withDocEOL: Yup.number().required('This field is required'),
          onlyCreditRecycling: Yup.number().required('This field is required'),
          withDocRecycling: Yup.number().required('This field is required'),
          validTill: Yup.string().required('Valid Till date is required'),
        })
      ),
    }),
    onSubmit: (values, actions) => {},
  });

  const columns = useMemo(
    () => [
      {
        id: 1,
        Header: '',
        columns: [
          {
            Header: '',
            accessor: 'category',
            Cell: (cellInfo) => (
              <span>{cellInfo?.value ? toCapitalize(cellInfo.value?.replace('-', ' ')) : '-'}</span>
            ),
          },
        ],
      },
      {
        Header: 'EOL',
        columns: [
          {
            Header: 'Only Credit',
            accessor: 'onlyCreditEOL',
            Cell: (cellInfo) => {
              return <span>{cellInfo.value}</span>;
            },
          },
          {
            Header: 'With Doc.',
            accessor: 'withDocEOL',
            Cell: (cellInfo) => {
              return <span>{cellInfo.value}</span>;
            },
          },
        ],
      },
      {
        Header: 'Recycling',
        columns: [
          {
            Header: 'Only Credit',
            accessor: 'onlyCreditRecycling',
            Cell: (cellInfo) => {
              return <span>{cellInfo.value}</span>;
            },
          },
          {
            Header: 'With Doc.',
            accessor: 'withDocRecycling',
            Cell: (cellInfo) => {
              return <span>{cellInfo.value}</span>;
            },
          },
        ],
      },
      {
        Header: 'Valid Till',
        columns: [
          {
            Header: '',
            accessor: 'validTill',
            Cell: (cellInfo) => {
              return <span>{cellInfo.value}</span>;
            },
          },
        ],
      },
      {
        Header: 'Trend',
        columns: [
          {
            Header: '',
            accessor: 'trend',
            Cell: (cellInfo) => {
              const fetchIcon = () => {
                if (cellInfo?.value === pricing_trend.up) {
                  return risingIcon;
                } else if (cellInfo.value === pricing_trend.down) {
                  return droppingIcon;
                } else return stableIcon;
              };
              return (
                <div className="updated-date">
                  {cellInfo?.value ? (
                    <>
                      <span className="mr-2">
                        {PRICING_TRENDS.find((trend) => trend?.id === cellInfo?.value)?.label}
                      </span>{' '}
                      <img src={fetchIcon()} height={15} width={15} alt="trend" />
                    </>
                  ) : (
                    '-'
                  )}
                </div>
              );
            },
          },
        ],
      },
    ],
    [formik.values]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data: formik.values.catItems,
    autoResetSelectedRows: false,
    autoResetFilters: false,
    autoResetSortBy: false,
    initialState: {
      hiddenColumns: [],
    },
    manualPagination: true,
  });

  return (
    <Modal show={showModal} closeButton onHide={onClose} centered size="xl" className="p-5">
      <Modal.Header className="border-0 pt-5 pr-5 pl-5 pb-1" style={{ display: 'block' }}>
        <Row className="mb-4">
          <Col md={6}>
            <h5>Brand Pricing List</h5>
          </Col>
          <Col md={6} className="text-right">
            <Button className="cancel-btn mr-2" variant="outline-secondary" onClick={onClose}>
              Cancel
            </Button>
          </Col>
        </Row>
      </Modal.Header>
      <Modal.Body className="pr-5 pl-5">
        <Row>
          <Col md={12}>
            {isLoading ? (
              <Loader />
            ) : (
              <Table {...getTableProps()} className="brand-pricing-table bordered" responsive>
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()} className="tr">
                      {headerGroup.headers.map((column) => (
                        <>
                          <th {...column.getHeaderProps()} className="th">
                            <div>{column.render('Header')}</div>
                          </th>
                        </>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {rows.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => (
                          <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            )}
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default BrandPricingModal;

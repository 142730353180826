import { detailedDiff } from 'deep-object-diff';
import { get, isEmpty, mapKeys, mapValues, omit, zip } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useRegionsMap } from '../../../hooks';
import { WORK_ORDER_DISPLAY_STATUS } from '../../../shared/Constants/Constants';
import { toCapitalize, toLocalDate } from '../../../shared/Utils/Helper';

function flattenObj(obj, parent, res = {}) {
  for (let key in obj) {
    let propName = parent ? parent + '.' + key : key;
    if (typeof obj[key] == 'object') {
      flattenObj(obj[key], propName, res);
    } else {
      res[propName] = obj[key];
    }
  }
  return res;
}

function WorkOrderChangeLog({
  logs,
  userList,
  browseNodes,
  workOrder,
  showModal,
  handleClose,
  toCustomerId,
}) {
  const { clients, serviceProviders } = useSelector(({ account, serviceProvider }) => ({
    clients: account.clients,
    serviceProviders: serviceProvider.listLight,
  }));

  const [extractedCreditInfo, setExtractedCreditInfo] = useState([]);
  const [hasCreditInfo, setHasCreditInfo] = useState(false);

  useEffect(() => {
    const extractedInfo = logs.map((log) => {
      try {
        const parsedJson = JSON.parse(log.json);
        if (parsedJson?.creditId) setHasCreditInfo(true);
        return {
          creditId: parsedJson?.creditId || 'N/A',
          vendorWorkorderIds: parsedJson?.assigned
            ? parsedJson.assigned
                .map((item) => item.vendorWorkorderId)
                .filter(Boolean)
                .join(', ')
            : 'N/A',
          brandWorkorderIds: parsedJson?.assigned
            ? parsedJson.assigned
                .map((item) => item.brandWorkorderId)
                .filter(Boolean)
                .join(', ')
            : 'N/A',
          totalAssignedCredit: parsedJson?.assigned
            ? parsedJson.assigned.reduce((sum, item) => sum + (item.assignedCredit || 0), 0)
            : 'N/A',
        };
      } catch (error) {
        console.error('Error parsing JSON:', error);
        return { creditId: 'N/A', vendorWorkorderIds: 'N/A', totalAssignedCredit: 'N/A' };
      }
    });

    setExtractedCreditInfo(extractedInfo);
  }, [logs]);

  let regionsMap = useRegionsMap();
  if (!logs?.length) return <></>;

  let diffs = zip(
    [null, ...logs.filter((log) => log.operation === null || log.operation === 'ASSIGNED_CREDIT')],
    logs.filter((log) => log.operation === null || log.operation === 'ASSIGNED_CREDIT')
    //logs
  )
    .filter(([prev, current]) => !!prev && !!current)
    ?.map(([prev, current]) => {
      let prevObj = prev && JSON.parse(prev.json);
      let currentObj = JSON.parse(current.json);

      prevObj = { ...prevObj, toCustomerId };
      currentObj = { ...currentObj, toCustomerId };

      [prevObj, currentObj] = [prevObj, currentObj].map((wo) => {
        const workOrder =
          toCustomerId === 8651
            ? wo?.assigned?.map((item) => item?.vendorWorkorderId).join(', ')
            : wo?.assigned?.map((item) => item?.brandWorkorderId).join(', ');

        return (
          wo && {
            'Service Provider':
              serviceProviders.find((e) => e.id === Number(wo?.toCustomerId))?.name || 'N/A',
            'Start Date': toLocalDate(wo?.fromDate) || 'N/A',
            'Estimated Delivery Date': toLocalDate(wo.toDate) || 'N/A',
            Status: toCapitalize(wo.status),
            Recycler:
              serviceProviders?.find(
                (item) => item.id === (wo?.recyclerCustomerId || wo?.recyclerId)
              )?.businessName || 'N/A',
            'Credit ID': wo?.creditId,
            'Transaction Id': wo?.transactionId,
            'To customerId': wo?.toCustomerId,
            'Assigned Qty ': wo?.assigned?.map((item) => {
              return {
                WO: workOrder,
              };
            }),
            Status: wo?.status,

            Item: wo?.workOrderItems?.map((woi) => {
              return {
                Material: woi?.itemName || 'N/A',
                State: regionsMap[woi?.stateId]?.name || 'N/A',
                District: regionsMap[woi?.stateId]?.districts[woi?.districtId]?.name || 'N/A',
                Target: woi?.qty || 'N/A',
                Rate: woi?.pricePerUnit || 'N/A',
                'Fulfilment Cycle': toCapitalize(woi?.fulfillmentCycle) || 'N/A',
                // 'Delivery Date': toLocalDate(woi?.estimatedDelivery) || 'N/A',
                'fulfilment plan':
                  (!isEmpty(woi?.fulfilmentPlan) &&
                    mapKeys(woi?.fulfilmentPlan, function (value, key) {
                      return key ? toLocalDate(key) : '';
                    })) ||
                  'N/A',
              };
            }),
            'PO Number': wo?.poNumber || 'N/A',
            'Entity Type': wo?.entityType || 'N/A',
            'Customer Type': wo?.customerType || 'N/A',
            'Payment Term': wo?.paymentTerm || 'N/A',
            'Traceability Documents': wo?.traceabilityDocuments === true ? 'Yes' : 'No',

            'Fulfillment Year':
              `${wo?.fulfillmentYearStartDate?.split('-')[0]} - ${
                wo?.fulfillmentYearEndDate?.split('-')[0]
              }` || 'N/A',
            'Extra comments': wo?.extraComments || 'N/A',
            Comments: wo?.comments || 'N/A',
          }
        );
      });

      let diff = prevObj && detailedDiff(currentObj, prevObj);
      if (diff) {
        diff.updated = mapValues(flattenObj(diff.updated), (v, k) => {
          return `${get(currentObj, k)} => ${v}`;
        });
        diff.deleted = Object.keys(flattenObj(diff.deleted));
      }
      return {
        ...omit(prev, 'json'),
        diff,
      };
    });

  function transformLogDataAction(wo) {
    const woData = JSON.parse(wo.json);
    if (!wo) return {};
    if (wo.operation === 'DOCUMENT_UPLOAD' || wo.operation === 'DOCUMENT_DELETE') {
      const currentObj = {
        'Document Type': toCapitalize(woData?.newData?.type.split('_').slice(1).join('_')),
      };
      return {
        ...omit(wo, 'json'),
        wo,
        currentObj,
      };
    } else {
      const currentObj = {
        Entity: woData?.newData?.Entity,
        'Vendor Name':
          (woData?.newData?.Entity === 'Vendor'
            ? serviceProviders?.find((sp) => sp?.id === woData?.newData?.CustomerID)?.businessName
            : clients?.find((user) => user?.id === woData?.newData?.CustomerID)?.name) || 'N/A',
        'Vendor Work Order ID': woData?.newData?.WorkOrderID || 'N/A',
        'Planned Quantity': woData?.newData?.Quantity || 'N/A',
        Price: woData?.newData?.Price || 'N/A',
        Category: woData?.newData?.Category || 'N/A',
      };
      if (wo.operation === 'FREED_QUANTITY') {
        delete currentObj['Planned Quantity'];
        currentObj['Freed Quantity'] = woData?.newData?.Quantity;
      }
      return {
        ...omit(wo, 'json'),
        wo,
        currentObj,
      };
    }
  }

  const returnStatusAction = (data) => {
    console.log(data, 'whatthedata');
    if (data && data.previousValue && data.previousValue.length > 0) {
      let hasStatus = false;
      let hasOtherKeys = false;

      data.previousValue.forEach((item) => {
        if (item.key === 'Status') {
          hasStatus = true;
        } else if (item.value && item.value.length > 0) {
          hasOtherKeys = true;
        }
      });
      if (!hasStatus && hasOtherKeys) {
        return 'ShowEdit';
      } else if (hasStatus && hasOtherKeys) {
        return 'ShowEditAndStatus';
      } else {
        return 'ShowStatus';
      }
    }

    if (data && data?.operation) {
      return 'ShowOperation';
    }

    return 'ShowStatus';
  };

  const diffsWithOperation = () => {
    const currentObj = logs
      .filter((log) => log.operation !== null)
      .map((item) => transformLogDataAction(item));
    return currentObj;
  };

  diffs = [
    ...diffs.filter(
      (diff) =>
        !isEmpty(diff?.diff?.updated) ||
        !isEmpty(diff?.diff?.added) ||
        !isEmpty(diff?.diff?.deleted)
    ),
    ...diffsWithOperation(),
  ].sort((a, b) => b.id - a.id);

  const displayDifference = (diffObj) => {
    const changes = {
      previousValue: [],
      currentValue: [],
    };
    const excludedKeys = [
      'Credit ID',
      'Transaction Id',
      'To customerId',
      'Assigned quantity',
      'Status',
    ];

    if (diffObj?.updated && Object.keys(diffObj.updated).length > 0) {
      Object.keys(diffObj.updated).forEach((key) => {
        const [previousValue, currentValue] = diffObj.updated[key]?.split(' => ');

        if (excludedKeys.some((excludedKey) => key.startsWith(excludedKey))) {
          if (currentValue !== undefined && currentValue !== null) {
            changes.currentValue.push({ key, value: currentValue });
          }
        } else {
          if (
            previousValue !== undefined &&
            previousValue !== 'undefined' &&
            previousValue !== null
          ) {
            changes.previousValue.push({ key, value: previousValue });
          } else {
            changes.previousValue.push({ key, value: '' });
          }
          if (currentValue !== undefined && currentValue !== 'undefined' && currentValue !== null) {
            changes.currentValue.push({ key, value: currentValue });
          }
        }
      });
    }

    if (diffObj?.added && Object.keys(diffObj.added).length > 0) {
      Object.keys(diffObj.added).forEach((key) => {
        if (!excludedKeys.some((excludedKey) => key.startsWith(excludedKey))) {
          changes.previousValue.push({ key, value: 'NA' });
        }
        if (diffObj.added[key] !== undefined && diffObj.added[key] !== null) {
          changes.currentValue.push({ key, value: JSON.stringify(diffObj.added[key], null, 2) });
        }
      });
    }

    if (diffObj?.deleted && diffObj.deleted.length > 0) {
      diffObj.deleted.forEach((key) => {
        if (!excludedKeys.some((excludedKey) => key.startsWith(excludedKey))) {
          changes.previousValue.push({ key, value: JSON.stringify(diffObj.deleted[key], null, 2) });
        }
        changes.currentValue.push({ key, value: 'NA' });
      });
    }

    return changes;
  };

  const data = [...diffs, logs[logs.length - 1]];
  let rejectionCounter = 0;

  const rejectedCounts = {};

  const rejectedCount = data.reduceRight((count, change, index, array) => {
    const diffData = change?.diff
      ? displayDifference(change.diff)
      : { previousValue: [], currentValue: [] };

    const countInCurrent = diffData?.currentValue?.filter(
      (item) => item.key === 'Status' && item.value === 'Rejected'
    ).length;

    if (countInCurrent > 0) {
      rejectionCounter++;
      rejectedCounts[index] = rejectionCounter;
    }
    return count + countInCurrent;
  }, 0);

  if (!logs?.length) return <></>;
  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      className="work-order-log-modal"
      size={'xl'}
      centered>
      <Modal.Header closeButton>
        <Modal.Title>Audit Trail</Modal.Title>
      </Modal.Header>

      <Modal.Body className="work-order-log">
        {!isEmpty(logs) && (
          <div className="table-wrapper">
            <table
              border={1}
              cellPadding={10}
              className="w-100 work-order-log-table table-responsive-sm table-responsive-md">
              <thead className="thead_logs">
                <tr>
                  <td>User & Timestamp</td>
                  <td>Action / Status update</td>
                  <td>Previous Value</td>
                  <td>Updated Value/ Comments</td>
                </tr>
              </thead>
              <tbody>
                {[...diffs, logs[logs.length - 1]].map((change, id) => {
                  // const diffData = change?.diff
                  //   ? displayDifference(change.diff)
                  //   : { previousValue: [], currentValue: [] };

                  let diffData = {};
                  if (
                    [
                      'PLANNED',
                      'FREED_QUANTITY',
                      'DOCUMENT_UPLOAD',
                      'DOCUMENT_DELETE',
                      'RE_PLANNED',
                    ].includes(change.operation)
                  ) {
                    diffData['previousValue'] = [];
                    diffData['currentValue'] =
                      change?.currentObj &&
                      Object.entries(change?.currentObj)
                        .filter(([key]) => !['Entity'].includes(key))
                        .map(([key, value]) => ({
                          key,
                          value,
                        }));
                    diffData['operation'] = change?.operation;
                  } else if (
                    change?.diff &&
                    ![
                      'PLANNED',
                      'FREED_QUANTITY',
                      'DOCUMENT_UPLOAD',
                      'DOCUMENT_DELETE',
                      'RE_PLANNED',
                    ].includes(change.operation)
                  ) {
                    diffData = displayDifference(change.diff);
                  } else diffData = { previousValue: [], currentValue: [] };

                  const showActionStatus = returnStatusAction(diffData);
                  console.log(showActionStatus, 'showActionStatus');
                  const statusText =
                    change?.status === 'IN_PROGRESS'
                      ? toCapitalize('APPROVED')
                      : change?.status === 'APPROVED'
                      ? 'Credits Approved'
                      : toCapitalize(change.status);
                  const rejectionDisplayText = (change, index) =>
                    change?.status === 'REJECTED' && rejectedCounts[index] > 0
                      ? `${statusText} (${rejectedCounts[index]})`
                      : statusText;

                  const showAction = () => {
                    if (showActionStatus === 'ShowEdit') {
                      return 'Edit';
                    } else if (
                      showActionStatus === 'ShowEditAndStatus' &&
                      change?.status !== 'CLOSED'
                    ) {
                      return `Edit / ${statusText}`;
                    } else if (change?.status === 'REJECTED') {
                      return rejectionDisplayText(change, id);
                    } else if (showActionStatus === 'ShowOperation') {
                      return toCapitalize(change?.operation);
                    } else return `${statusText}`;
                  };

                  console.log(diffData, 'diffData');
                  return (
                    <tr key={`${change?.changedBy}${id}`}>
                      <td>
                        <p className="mb-3 text_value_action_user">
                          {userList?.some((user) => user?.id === change?.changedBy)
                            ? userList?.find((user) => user?.id === change?.changedBy)?.name
                            : serviceProviders?.some((user) => user?.id === change?.customerId)
                            ? serviceProviders?.find((user) => user?.id === change?.customerId)
                                ?.name
                            : clients?.find((user) => user?.id === change?.customerId)?.name ||
                              change?.changedByUserName}{' '}
                        </p>
                        <p className="text_value_action_user">
                          {toLocalDate(change.changedOn, 'DD/MM/YYYY h:mm a') || 'N/A'}
                        </p>
                      </td>
                      <td className="text_value_action_user">
                        {showActionStatus ? showAction() : `${statusText}`}
                      </td>
                      <td>
                        {diffData.previousValue.map((item, i) => {
                          if (![WORK_ORDER_DISPLAY_STATUS.CLOSED.id].includes(change?.status)) {
                            return (
                              <p key={i} className="audit_log_text_value">
                                {item.key}:{' '}
                                <span
                                  className="audit_log_prev-value"
                                  style={
                                    item?.value?.length >= 100
                                      ? { height: '150px', width: '300px' }
                                      : {}
                                  }>
                                  {item.value ? item.value : 'N/A'}
                                </span>
                              </p>
                            );
                          }
                          return null;
                        })}
                      </td>
                      <td>
                        {diffData?.currentValue?.map((item, i) => {
                          if (![WORK_ORDER_DISPLAY_STATUS.CLOSED.id].includes(change?.status)) {
                            return (
                              <p key={i} className="audit_log_text_value">
                                {item.key}:{' '}
                                <span
                                  className="audit_log_prev-value"
                                  style={
                                    item?.value?.length >= 100
                                      ? { height: '150px', width: '300px' }
                                      : {}
                                  }>
                                  {item.value ? item.value : 'N/A'}
                                </span>
                              </p>
                            );
                          }
                          return null;
                        })}

                        {change?.remark && (
                          <span className="audit_log_text_value">
                            Reason:
                            <small
                              style={
                                change?.remark?.length >= 100
                                  ? { height: '150px', width: '300px' }
                                  : {}
                              }
                              className="audit_log_remarks">
                              {change?.remark}
                            </small>
                          </span>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default WorkOrderChangeLog;

import API from '../../shared/Config/Api';
import { default as APIEndpoints } from '../../shared/Config/ApiEndpoints.const';

const getVendorPricing = async () => {
  return await API.get(`${APIEndpoints.VENDOR_PRICING}?moduleType=vendor&attributeType=pricing`);
};

const updateVendorPricing = async (payload) => {
  return await API.put(APIEndpoints.VENDOR_PRICING, payload);
};

const createVendorPricing = async (payload) => {
  return await API.post(APIEndpoints.VENDOR_PRICING, payload);
};

const getBrandPricing = async () => {
  return await API.get(`${APIEndpoints.BRAND_PRICING}?moduleType=vendor&attributeType=pricing`);
};

const fetchSummary = async (params) => {
  return await API.get(APIEndpoints.MARGIN_APPROVAL_SUMMARY, null, params);
};

const fetchMarginList = async (params) => {
  const response = await API.get(APIEndpoints.MARGIN_APPROVAL, null, params);
  const totalCount = Number(response?.headers['x-total-count']);
  return { marginList: response?.data, totalCount };
};

const marginApprovalAction = async (type, params) => {
  return await API.put(`${APIEndpoints.MARGIN_APPROVAL}/${type}/action`, null, params);
};

const auditLogs = async (params) => {
  const response = await API.get(`${APIEndpoints.MARGIN_APPROVAL}/audit-logs`, null, params);
  const totalCount = Number(response?.headers['x-total-count']);
  return { marginLogs: response?.data, totalCount };
};

export const MarginApproval = {
  getVendorPricing,
  updateVendorPricing,
  createVendorPricing,
  getBrandPricing,
  fetchSummary,
  fetchMarginList,
  marginApprovalAction,
  auditLogs,
};

import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { HasAuthority } from '../../../shared/Components';
import { useAuthority } from '../../../shared/Components/HasAuthority/HasAuthorityNew';
import { AUTHORITY2 } from '../../../shared/Constants/Constants';
import { formatIndianNumber } from '../../../shared/Utils/Utils';
import { getExecutionData } from '../../ExecutionModule/service';
import './ExecutionContainer.scss';
import ExecutionTargetTable from './ExecutionTargetTable';
import NewExecutionFilters from './NewExecutionFilters';

const NewExecutionScreen = () => {
  const { hasAuth } = useAuthority();
  const history = useHistory();
  const isBrand = hasAuth([AUTHORITY2.BRAND]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const { isUsingFulfilment, selectedFulfilmentYear, selectedFinancialYear } = useSelector(
    (state) => state?.account
  );
  const [filters, setFilters] = useState({
    browseNodeIds: null,
    itemIds: null,
    stateIds: null,
    districtIds: null,
    clientId: null,
    serviceProviderId: null,
  });
  const clearFilters = () => {
    setFilters({
      browseNodeIds: null,
      itemIds: null,
      stateIds: null,
      districtIds: null,
      clientId: null,
      serviceProviderId: null,
    });
  };

  const getExecutionDetails = async () => {
    let payload = {
      stateIds:
        filters?.stateIds && filters?.stateIds?.length > 0
          ? filters?.stateIds?.join(',')
          : undefined,
      browseNodeIds:
        filters?.browseNodeIds && filters?.browseNodeIds?.length > 0
          ? filters?.browseNodeIds?.join(',')
          : undefined,
      itemIds:
        filters?.itemIds && filters?.itemIds?.length > 0 ? filters?.itemIds?.join(',') : undefined,
      'fromCustomerId.equals': filters?.clientId || undefined,
      'fromDate.equals': isUsingFulfilment ? undefined : selectedFinancialYear?.fromDate,
      'toDate.equals': isUsingFulfilment ? undefined : selectedFinancialYear?.toDate,
      'fulfillmentYearStartDate.equals': isUsingFulfilment
        ? selectedFulfilmentYear?.fromDate
        : undefined,
      'fulfillmentYearEndDate.equals': isUsingFulfilment
        ? selectedFulfilmentYear?.toDate
        : undefined,
    };
    setLoading(true);
    const res = await getExecutionData(payload);
    if ([200, 201].includes(res?.status)) {
      let resultedData = res?.data?.filter((item) => item?.category !== 'BASE');
      setData(resultedData);
      let baseData = res?.data?.filter((item) => item?.category === 'BASE');
      const total = baseData?.map((item) => {
        const baseValues = item?.baseValues?.find(
          (item) => !item?.traceability && !item?.creditType
        );
        return baseValues;
      });
      let totalQuantity = total[0]?.value || baseData[0]?.totalQuantity;
      setTotalQuantity(totalQuantity);
      setLoading(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    getExecutionDetails();
  }, [
    filters,
    isUsingFulfilment,
    selectedFinancialYear?.fromDate,
    selectedFulfilmentYear?.fromDate,
  ]);

  // const getQueryParams = (dataRow, fields) => {
  //   let currentUrlParams = new URLSearchParams();
  //   let obj = {
  //     itemIds: dataRow.data.itemId,
  //     stateIds: dataRow.data.stateId,
  //     ...fields,
  //   };
  //   Object.keys(obj).forEach((keyItem) => {
  //     if (obj[keyItem] === null || obj[keyItem] === undefined || obj[keyItem] === 0) {
  //       currentUrlParams.delete(keyItem);
  //     } else {
  //       currentUrlParams.set(keyItem, obj[keyItem]);
  //     }
  //   });
  //   return currentUrlParams;
  // };
  const proClientColumns = [
    {
      dataField: 'creditsDocuments.endOfLife',
      heading: 'End of Life',
      sortOrder: 'desc',
      sortUsingRenderer: false,
      styleClass: 'proCol client-side',
      renderer: function (dataRow, dataField) {
        return (
          <span>
            {formatIndianNumber(Math.round(dataRow?.data?.creditsDocuments?.endOfLife)) || 0}
          </span>
        );
      },
    },
    {
      dataField: 'creditsDocuments.recycling',
      heading: 'Recycling',
      styleClass: 'proCol client-side',
      sortUsingRenderer: false,
      renderer: function (dataRow, dataField) {
        return (
          <span>
            {formatIndianNumber(Math.round(dataRow?.data?.creditsDocuments?.recycling)) || 0}
          </span>
        );
      },
    },
  ];
  const proVendorColumns = [
    {
      dataField: 'onlyCredits.endOfLife',
      heading: 'End of life',
      styleClass: 'proCol vendor-side',
      renderer: function (dataRow, dataField) {
        return (
          <span>{formatIndianNumber(Math.round(dataRow?.data?.onlyCredits?.endOfLife)) || 0}</span>
        );
      },
    },
    {
      dataField: 'onlyCredits.recycling',
      heading: 'Recycling',
      sortUsingRenderer: false,
      styleClass: 'proCol vendor-side',
      renderer: function (dataRow, dataField) {
        return (
          <span>{formatIndianNumber(Math.round(dataRow?.data?.onlyCredits?.recycling)) || 0}</span>
        );
      },
    },
  ];
  const proTotalQuantityColumn = [
    {
      dataField: 'totalQuantity',
      heading: 'Total Quantity',
      styleClass: 'proCol vendor-side',
      renderer: function (dataRow, dataField) {
        return <span>{formatIndianNumber(Math.round(dataRow.data?.totalQuantity)) || 0}</span>;
      },
    },
  ];
  const getColumns = () => {
    let columns = {
      clientColumns: proClientColumns,
      vendorColumns: proVendorColumns,
      totalQuantityColumns: proTotalQuantityColumn,
    };
    return {
      fixedColumnsLeft: fixedColumnsLeft,
      ...columns,
    };
  };
  return (
    <Container fluid className="animated fadeIn">
      <Row className="newexecutionContainer d-flex align-items-center justify-content-between">
        <Col className="d-flex align-self-center">
          <HasAuthority authorities={[AUTHORITY2.PRO]}>
            <h4 className="ml-3">Unplanned Demand</h4>
          </HasAuthority>
        </Col>
      </Row>
      <Row className="w-100 my-2">
        <NewExecutionFilters data={data} filters={filters} setFilters={setFilters} />
      </Row>
      <ExecutionTargetTable
        data={data}
        filters={filters}
        totalQuantity={totalQuantity}
        columns={getColumns()}
        clearFilters={clearFilters}
        loading={loading}
      />
    </Container>
  );
};

export default NewExecutionScreen;

const fixedColumnsLeft = [
  {
    dataField: 'name',
    heading: 'Material',
    sortable: true,
    styleClass: 'itemCol',
  },
];
